import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import CustomizedDialogs from "./DialogBox";
import useClickOutside from "use-click-outside";
import { getToken } from "../utils/Auth";
import { removeToken } from "../utils/Auth";
import axios from "../axiosInstance";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SetAuthToken from "../SetAuthToken";

const AppBar = () => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      let parseUserData = JSON.parse(userData ?? '{}');
      if (parseUserData?.usertoken) {
        SetAuthToken(parseUserData?.usertoken);
      }
    };
    getUserInfo();
  }, []);
  const [loggedInUserData, setLoggedInUserData] = useState([]);
  const [logout, setLogout] = useState(false);
  const ref = useRef();
  const token = getToken();
  const getUserInfo = async () => {
    let response;
    try {
      response = await axios.get("/user/me");
      if (response?.data?.type === "success") {
        setLoggedInUserData(response?.data?.data);
      }
      console.log(response, "response.....");
    } catch (e) {
      console.trace(e);
      if (e?.response?.data?.message == "jwt expired") {
        removeToken();
      }
    }
  };

  const Logout = (value) => {
    if (value) {
      setLogout(!logout);
    }
  };

  function meanuToggle() {
    $("#mobile-side-drawer").on("click", function () {
      $(".mobile-sider-drawer-menu").toggleClass("active");
    });
  }
  // function ToggleNav() {
  //   document
  //     .getElementById("mobile-side-drawer")
  //     .addEventListener("click", toggleSidenav);
  // }
  // function toggleSidenav() {
  //   document
  //     .querySelector(".mobile-sider-drawer-menu")
  //     .classList.toggleClass("active");
  // }

  const [ColorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 300) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  useClickOutside(ref, () => {
    setLogout(false);
  });
  return (
    <>
      {/* <!-- HEADER START --> */}
      <header className="site-header header-style-3 mobile-sider-drawer-menu ">
        <div
          onClose={() => setLogout(false)}
          className="sticky-wrapper"
          style={{ height: "90px;" }}
        >
          <div className="is-fixed main-bar-wraper navbar-expand-lg sticky-header">
            <div
              style={{ background: "#ffffff" }}
              className={
                ColorChange ? "main-bar  color-fill shadow" : "main-bar shadow"
              }
            >
              <div className="container-fluid clearfix">
                <div className="logo-header">
                  <div className="logo-header-inner logo-header-one">
                    <Link to="/">
                      <img src="./paasa-logo.png" alt="" />
                    </Link>
                  </div>
                </div>

                {/* <!-- NAV Toggle Button --> */}
                <button
                  onClick={meanuToggle}
                  id="mobile-side-drawer"
                  data-target="#header-nav"
                  data-toggle="collapse"
                  type="button"
                  aria-controls="header-nav"
                  className="navbar-toggler collapsed"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar icon-bar-first"></span>
                  <span className="icon-bar icon-bar-two"></span>
                  <span className="icon-bar icon-bar-three"></span>
                </button>

                {/* <!-- MAIN Vav --> */}
                <div
                  id="header-nav"
                  className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center"
                >
                  <ul className=" nav navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/AboutPaasa">
                        About PAASA
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ManagementCommittee">
                        Management committee
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/NewsAndEvents">
                        Events
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Covid19Info">
                        COVID 19 info
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link className="nav-link active" to="/BookVehicle">
                        Book a Vehicle
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <!-- Header Right Section--> */}
                <div className="extra-nav header-2-nav">
                  {token ? (
                    <>
                      <span className="btn btn-outline-gray">
                        {loggedInUserData?.profile?.firstName ? (
                          loggedInUserData?.profile?.firstName
                        ) : (
                          <CircularProgress size={30} color="success" />
                        )}
                      </span>
                      <span
                        className="btn btn-outline-gray"
                        ref={ref}
                        onClick={() => Logout(true)}
                      >
                        {loggedInUserData?.profile?.firstName ? (
                          <div
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            Profile
                          </div>
                        ) : (
                          <CircularProgress size={30} color="success" />
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <Link to="/LogIn" className="btn btn-outline-gray">
                        Login
                      </Link>
                      <Link to="/SignUp" className="btn btn-outline-gray">
                        Join Us
                      </Link>
                    </>
                  )}
                  {logout ? (
                    <>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => navigate("/userprofile")}>
                          User Profile
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            navigate("/userprofile", { state: { num: 2 } })
                          }
                        >
                          Event Tickets
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            navigate("/userprofile", { state: { num: 3 } })
                          }
                        >
                          Change Password
                        </MenuItem>

                        <MenuItem onClick={removeToken}>Logout</MenuItem>
                      </Menu>
                    </>
                  ) : null}

                  {/* <button className="btn btn-green ">$ Donate us</button> */}
                  <CustomizedDialogs />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- HEADER END --> */}
    </>
  );
};

export default AppBar;
