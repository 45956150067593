import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AppBar from '../../commonComponents/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import axios from '../../axiosInstance';
import { UpdateStore } from '../../StoreContext';
import { getPlanActiveEndDateYear } from "../../utils/Helpers";

let date = new Date();
const MembershipPlan = () => {
  useEffect(() => {
    getMemberShipPlans();
    getadds();
  }, []);

  const [memberShipPlanData, setMemberShipPlanData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adds, setadds] = useState();
  const navigate = useNavigate();
  let location = useLocation();
  const updateStore = UpdateStore();
  const getadds = async () => {
    let response;
    console.log(location, 'location.pathname');
    try {
      response = await axios.get(`/advertisement${location.pathname}`);
      if (response?.data?.type === 'success') {
        console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };

  const getMemberShipPlans = async () => {
    let response;
    try {
      response = await axios.get('/plans/');
      if (response?.data?.type === 'success') {
        setMemberShipPlanData(response?.data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.trace(e?.message);
    }
  };
  return (
    <>
      <AppBar />

      <section className="p-t120 membership-plan">
        <div className="wt-bnr-inr  p-t50">
          <div className="container-lg">
            <div className="banner-nav">
              <Link to="/SignUp"> Create account </Link>
              <i className="	fa fa-angle-left"></i>
              <span className="green-text">Choose membership plan</span>
            </div>
            <div className="banner-title-name m-t40">
              <h1 className="wt-title">Membership Benifits</h1>
            </div>
            <div className="home-para">
              <p>
                Preference will be given to Members & Member’s Kids for all Cultural Programmes & Events. Member’s kids get
                exclusive access to youth committee and youth activities. Inculcate a spirit of friendship, harmony and
                cooperation with all other Pakistani associations in South Australia particularly and other communities in
                Australia in general for welfare of the community. Access to special Developmental Initiatives & Community Service
                Activities and enhance Leadership qualities in Youth Committee members.
              </p>
            </div>
          </div>
        </div>
        <div className="wt-bnr-inr  ">
          <div className="container-lg">
            <div className="banner-title-name">
              <h1 className="wt-title">Membership Type</h1>
            </div>
            <div className="home-para ">
              <p>
                Membership per Family per Year (Jun –July) includes not only family members but their visiting Parents as well.
                Excellent Networking opportunity for members to touch-base with largest Pakistani community across South
                Australian region. Tools provided exclusively to members. Opportunity to celebrate major Pakistani events with the
                community. Special discounted prices for Members at sponsored vendor businesses and PAASA social events.
              </p>
            </div>
          </div>
        </div>
        <div className="wt-bnr-inr  ">
          <div className="container-lg">
            <div className="banner-title-name">
              <h1 className="wt-title">Choose membership plan</h1>
              {loading ? (
                <div className="d-flex">
                  <CircularProgress size="20vh" />
                </div>
              ) : (
                <div className="membership-cards">
                  <div className="row">
                    {memberShipPlanData.map((_, index) => {
                      return (
                        <>
                          <div className="col-xl-4 col-sm-6 col-12" key={index}>
                            <div class="card text-center">
                              <div class="card-header shadow-sm">{_?.name}</div>
                              <div class="card-body">
                                {index === 3 ? (
                                  <div className="card-link">Free access for three months</div>
                                ) : (
                                  <p class="card-title">Access until Jun 30, {getPlanActiveEndDateYear()}</p>
                                )}

                                <div className="card-price"> ${_?.price} </div>
                                <button
                                  className="btn btn-green"
                                  onClick={() => {
                                    navigate('/MemberDetail', {
                                      state: {
                                        packagePrice: _?.price,
                                        packageName: _?.name,
                                        planId:_?.id
                                        // signupToken: location?.state?.signupToken,
                                      },
                                    });
                                    updateStore({
                                      packagePrice: _?.price,
                                      packageName: _?.name,
                                      planId:_?.id
                                    });
                                  }}
                                >
                                  Become a member
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex adds-img w-100">
          <img style={{ height: '400px' }} src={adds?.picture ?? '../../../home/addsdummy.png'} />
        </div>
      </section>
    </>
  );
};

export default MembershipPlan;
