import React, { useState, useEffect } from 'react';
import axios from '../../axiosInstance';

const AdsBanner = () => {
  const [adds, setadds] = useState();
  useEffect(() => {
    getadds();
  }, []);
  const getadds = async () => {
    let response;
    try {
      response = await axios.get(`/advertisement/AboutPaasa`);
      if (response?.data?.type === 'success') {
        console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  return (
    <>
      <section>
        <div className="ad-banner">
          <div className="green-left d-none d-md-block"></div>
          <div className="banner-center conatiner">
            <div className="banner-containt row">
              <div className="banner-img col-md-2"></div>
              <img style={{ height: '300px' }} src={adds?.picture ?? '../../../home/addsdummy.png'} />
              <div className="banner-btn col-md-2"></div>
            </div>
          </div>
          <div className="green-right d-none d-md-block"></div>
        </div>
      </section>
    </>
  );
};

export default AdsBanner;
