import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppBar from "../../commonComponents/AppBar";
import InputComponent from "../../commonComponents/Input/InputComponent";
import ButtonComponent from "../../commonComponents/Button/ButtonComponent";
import axios from "../../axiosInstance";
// import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ForgetPassword2 = () => {
  const [formData, setFormData] = useState({});
  const [resetToken, setResetToken] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  
  // Extracting the domain name from the URL
  // console.log("Doamin",domainName, window.location);
  // console.log(token, "tk");
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setFormData({ ...formData, ...obj });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData?.password !== formData?.confirmPassword) {
      // toast.error("Password and Confirm Password should be same");
      Swal.fire(
        "Password Validation",
        "Password and Confirm Password should be same",
        "error"
      );
      return;
    };

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])[a-zA-Z\d@#$%^&+=]{8,}$/;
    if (!passwordRegex.test(formData?.password)) {
      Swal.fire(
        "Password Validation",
        "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, and one symbol i.e( @#$%^&+= ) ",
        "error"
      );
      return;
    }
    let res;
    try {
      res = await axios({
        method: "post",
        url: "/user/setPassword",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        data: formData,
      });
      // console.log(res);
      if (res?.data?.type === "success") {
        // toast.success(res?.data?.message);
        // Swal.fire("Password ", res?.data?.message, "success");
        // setTimeout(() => {
          window.location = "/SetPasswordConfirmation"
        // }, timeout);
        // window.location = "/LogIn";
      }
    } catch (error) {
      Swal.fire("Reset Password Failed", `Your link is expire or incorrect. </br> Please regenrate password reset link <a href="/ForgetPassword"> from here </a>`, "error");
    }
    /////
  };
  return (
    <>
      <AppBar />
      <div className="form-contan ">
        <div className="long-form  logIn-form ">
          <div className="container-lg">
            <div className="form-heading ">
              <h4>Set Password </h4>
            </div>

            <form type="submit" onSubmit={handleSubmit}>
              <InputComponent
                required={true}
                type="password"
                className="form-control email-icon"
                placeholder="Password"
                onChange={(e) => _setState("password", e?.target?.value)}
              />
              <InputComponent
                required={true}
                type="password"
                className="form-control email-icon"
                placeholder="Confirm Password"
                onChange={(e) => _setState("confirmPassword", e?.target?.value)}
              />
              <ButtonComponent
                className="btn btn-green "
                text="Submit"
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword2;
