import React, { useMemo, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from "../../axiosInstance";

import { toast } from "react-toastify";

import useResponsiveFontSize from "../../commonComponents/useResponsiveFontSize";
import Swal from "sweetalert2";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const BookingVehicalePaymentCard = ({
  BillingData,
  formData,
  getVehicles,
  setSteps,
  setOpen,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    //Client Sectet Get from Server:
    let response;
    try {
      let data = {
        amount: BillingData?.totalPriceWithSecurity,
      };
      response = await axios.post("/payment/createPayment", data);
      if (response?.data?.type == "success") {
        const clientSecret = response?.data?.data?.clientSecret;
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: { card: elements.getElement(CardElement) },
        });
        if (paymentIntent?.status === "succeeded") {
          const obj = {
            ...formData,
            amountCharged: BillingData?.totalPriceWithSecurity,
          };
          try {
            const response = await axios.post("/vehicleBooking/addBooking", obj);
            if (response?.data?.type == "success") {
              // toast.success("Data Saved Successfully");
              Swal.fire("Booking Processed", "Data Saved Successfully", "success");
              getVehicles();
              setSteps("");
              setOpen(false);
            }
            else if (response?.data?.type == "error") {
              Swal.fire("Booking Processing Failed", response?.data?.message, "error");
            }
          } catch (e) {
            Swal.fire("Booking Processing Failed", e?.response?.data?.message, "error");
          }
        }
        else if (error?.message) {
          Swal.fire("Payment Processing Failed", error?.message, "error");
        }
        else {
          // toast.error(paymentIntent.cancellation_reason);
          Swal.fire("Payment Processing Failed", paymentIntent?.cancellation_reason, "error");
          //show user to cancel reason {paymentIntent.cancellation_reason}
        }
      }
    } catch (e) {
      // toast.error(e.response.data.message);
      Swal.fire("Payment Processing Failed", e.response.data.message, "error");
    }

  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <label className="w-100 parent-label">
          Card details
          <CardElement
            options={options}
            onReady={() => {
              console.log("CardElement [ready]");
            }}
            onChange={(event) => {
              console.log("CardElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardElement [blur]");
            }}
            onFocus={() => {
              console.log("CardElement [focus]");
            }}
          />
        </label>
        <button type="submit" disabled={!stripe}>
          Pay Now
        </button>
      </form>
    </>
  );
};

export default BookingVehicalePaymentCard;
