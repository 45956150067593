import React, { useEffect, useState } from "react";
import AppBar from "../../commonComponents/AppBar";
import { useLocation } from "react-router-dom";
import axios from "../../axiosInstance";
import { color } from "@mui/system";

const MeetTheCommunity = () => {
  const [CommunityData, setCommunityData] = useState();
  const [DesignationName, setDesignationName] = useState(false);
  const [adds, setadds] = useState();
  useEffect(() => {
    getCommunity();
    getadds();
  }, []);

  const getCommunity = async () => {
    let response;
    try {
      response = await axios.get("/communityMember/list");
      setCommunityData(response?.data?.data);
    } catch (e) {
      console.trace(e);
    }
  };
  const location = useLocation();
  const getadds = async () => {
    let response;
    try {
      response = await axios.get(`/advertisement${location.pathname}`);
      if (response?.data?.type === "success") {
        console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };

  console.log(CommunityData, "CommunityData");
  return (
    <>
      <AppBar />
      <section className="m-t100">
        <div className="wt-bnr-inr  p-t50">
          <div className="container-lg">
            <div className="banner-title-name m-t40 m-b20">
              <h1 className="wt-title">Meet The Community</h1>
            </div>
          </div>
        </div>
        {/* {
            CommunityData?.map((val)=>(
                <> 
        <div className="wt-bnr-inr  p-t100">
          <div className="container-lg">
            <div className="banner-title-name m-t40">
              <h1 className="wt-title">{val?.name}</h1>
            </div>
            <div className="para">
              <p>
                {val?.description}
              </p>
              <img src={val?.picture}/>
            </div>
          </div>
        </div>
        </>
           )) } */}

        <div
          className="community-data-container"
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            margin: "0% auto 5% auto",
          }}
        >
          {CommunityData?.map((val) => (
            <>
              <div>
                <img
                  src={val?.picture}
                  style={{
                    height: "300px",
                    width: "250px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "10px",
                  }}
                  alt="profile pic"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "5px 0px",
                  }}
                >
                  <span style={{ fontSize: "19px", fontWeight: "500" }}>
                    {val?.name}
                  </span>
                  <span>{val?.designation}</span>
                </div>
              </div>
            </>
          ))}
        </div>

        {/* <div className="wt-bnr-inr  ">
          <div className="container-lg">
            <div className="banner-title-name">
              <h1 className="wt-title">Executive committee</h1>
            </div>
            <div className="para ">
              <p>
                If you are passionate about community work then PAASA welcomes
                you to join us as executive member and be the part of team in
                any of the following portfolio, New Migrants Assistance, Women
                representative, Sports, Urdu School, Funeral Fund Program,
                Community Liaison, Student representative and Business and trade
                liaison.
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="wt-bnr-inr  ">
          <div className="container-lg">
            <div className="banner-title-name">
              <h1 className="wt-title">Volunteers</h1>
            </div>
            <div className="para">
              <p>
                We organize different social and cultural programs for the
                community. Pakistan Day, Eid -al-Fiter, Eid -al-Adha, Pakistan
                Independence Day, Community BBQ Picnic and Australia Day are our
                fixed events. Besides these events we also organize new migrants
                meet and greet sessions, one dish parties and small gatherings.
                We are always in need of volunteers to come help us in
                organizing these community events. If you love to take pride in
                volunteer work please let us know.
              </p>
            </div>
          </div>
        </div> */}
        <div className="d-flex adds-img w-100">
          <img
            style={{ height: "400px" }}
            src={adds?.picture ?? "../../../home/addsdummy.png"}
          />
        </div>
      </section>
    </>
  );
};

export default MeetTheCommunity;
