import React, { useState, useEffect } from "react";

import AppBar from "../../commonComponents/AppBar";
import VerificationModal from "../../commonComponents/Modal/VerificationModal";
import Label from "../../commonComponents/Label/Label";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import axios from "../../axiosInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import _ from "lodash";

const SignUp = () => {
  const [SignUpData, setSignUpData] = useState({
    userRole: "VOLUNTEER",
  });
  const [emailError, setEmailError] = useState();
  const [loading, setLoading] = useState(true);
  const [countriesList, setCountriesList] = useState();
  const [verificationCode, setverificationCode] = useState();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    CountriesList();
    document.title = "paasa Sign up";
  }, []);

  const navigate = useNavigate();
  const CountriesList = async () => {
    try {
      let response = await axios.get("https://restcountries.com/v3.1/all");
      console.log(response?.data, "response");
      const sortedData = response?.data.sort((a, b) => {
        const aName = a.name.common;
        const bName = b.name.common;
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      });
      setCountriesList(sortedData);
    } catch (error) {
      console.trace(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (SignUpData?.password !== SignUpData?.confirmpassword) {
      // toast.error("password and confirm password should be same");
      Swal.fire(
        "Error",
        "password and confirm password should be same",
        "error"
      );
      return;
      setOpen(false);
    } else if (!_.isEmpty(emailError)) {
      Swal.fire(
        "Email Validation",
        emailError,
        "error"
      );
      return;
    }
    let response;
    try {
      let data = {
        email: SignUpData?.email,
      };

      response = await axios.post("/user/sendEmailVerification", data);
      if (response?.data?.type === "success") {
        setLoading(false);
        setOpen(true);
      }
    } catch (e) {
      // toast.error(e.response.data.message);
      Swal.fire("Error", e?.response?.data?.message, "error");

      setOpen(false);
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (SignUpData?.password != SignUpData?.confirmpassword) {
  //     toast.error('password and confirm password should be same');
  //     return;
  //   }
  //   updateStore({
  //     signupToken: '',
  //   });
  //   const data = {
  //     email: SignUpData?.email,
  //     password: SignUpData?.password,
  //     userRole: SignUpData?.userRole,
  //     isAcceptedTerms: true,
  //     profile: {
  //       firstName: SignUpData?.firstName,
  //       lastName: SignUpData?.lastName,
  //       phoneNumber: SignUpData?.phoneNumber,
  //       addressLine1: SignUpData?.addressLine1,
  //       city: SignUpData?.city,
  //       state: 'SouthAustralia',
  //       zipCode: SignUpData?.zipCode,
  //       nationality: SignUpData?.nationality,
  //     },
  //   };
  //   let response;
  //   try {
  //     response = await axios.post('/user/register', data);
  //     if (response?.data?.type == 'success') {
  //       toast.success('Data Saved Succesfully');
  //       setAuthToken(response?.data?.data?.token);
  //       setOpen(true);
  //     }
  //     console.log(response.data?.type);
  //   } catch (e) {
  //     toast.error(e.response.data.message);
  //   }
  //   return;
  // };
  const verifyCode = async (verificationcode) => {
    const data = {
      code: verificationcode,
      email: SignUpData?.email,
    };
    let response;
    try {
      response = await axios.post("/user/emailVerification", data);
      if (response?.data?.type === "success") {
        const data = {
          email: SignUpData?.email,
          password: SignUpData?.password,
          userRole: SignUpData?.userRole,
          isAcceptedTerms: true,
          profile: {
            firstName: SignUpData?.firstName,
            lastName: SignUpData?.lastName,
            phoneNumber: SignUpData?.phoneNumber,
            addressLine1: SignUpData?.addressLine1,
            city: SignUpData?.city,
            state: "SouthAustralia",
            zipCode: SignUpData?.zipCode,
            nationality: SignUpData?.nationality,
          },
        };
        try {
          response = await axios.post("/user/register", data);
          if (response?.data?.type === "success") {
            // toast.success("Data Saved Succesfully");
            Swal.fire(
              "Verified Successfully!",
              "You have successfully verified account!",
              "success"
            );
            setOpen(false);
            const dataObj = {
              usertoken: response?.data?.data?.token,
            };

            localStorage.setItem("userData", JSON.stringify(dataObj));
            if (SignUpData?.userRole === "FINANCIAL") {
              navigate("/MembershipPlan", {
                state: {
                  signupToken: response?.data?.data?.token,
                },
              });
            } else {
              navigate("/AboutPaasa");
            }
          }
        } catch (e) {
          // toast.error(e.response.data.message);
          Swal.fire("Error", e?.response?.data?.message, "error");
          setOpen(false);
          if (
            e?.response?.data?.message ==
            "Email already exist, Please try with different email. User not added"
          ) {
            setEmailError(
              "Email already exist, Please try with different email. User not added"
            );
          }
        }
        return;
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      Swal.fire("Error", error?.response?.data?.message, "error");
      setOpen(false);
    }
  };
  const onChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setSignUpData((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const handleDuplicateEmail = async (e) => {
    try {
      let data = { email: e.target.value };
      let response = await axios.post("/user/checkDuplicateEmail", data);
      if (response?.status == 200) {
        setEmailError("");
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      if (error?.response?.status == 500) {
        setEmailError("Email already exist, Please try with different email.");
      }
    }
  };

  return (
    <>
      <AppBar />
      <VerificationModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        verifyCode={verifyCode}
        verificationCode={verificationCode}
        setverificationCode={setverificationCode}
      />
      <div className="form-contan">
        <div className="long-form  logIn-form signUp">
          <div className="container-lg">
            {/* <div className="close-icon">
      <Close />
    </div> */}
            <div className="form-heading ">
              <h4>Create your account</h4>
            </div>
            <form type="submit" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <span className="SignUpAs">Sign up as</span>
                </div>
                <div className="col-lg-12">
                  <div class="form-check form-check-inline">
                    <input
                      defaultChecked={SignUpData?.userRole == "VOLUNTEER"}
                      onChange={onChange}
                      type="radio"
                      name="userRole"
                      id="inlineRadio1"
                      value="VOLUNTEER"
                    />
                    <label
                      class="form-check-label radio-label"
                      for="inlineRadio1"
                    >
                      Volunteer
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      checked={SignUpData?.userRole == "COMMUNITY"}
                      onChange={onChange}
                      type="radio"
                      name="userRole"
                      id="inlineRadio2"
                      value="COMMUNITY"
                    />
                    <label
                      class="form-check-label radio-label"
                      for="inlineRadio2"
                    >
                      Community user
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      defaultChecked={SignUpData?.userRole == "FINANCIAL"}
                      onChange={onChange}
                      type="radio"
                      name="userRole"
                      id="inlineRadio3"
                      value="FINANCIAL"
                    />
                    <label
                      class="form-check-label radio-label"
                      for="inlineRadio3"
                    >
                      Financial member
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div className="col-lg-12">
                  <div className="d-flex col-lg-12 space-between signup-input">
                    <div className="col-lg-6 m-r10">
                      <Label text="First Name*" />
                      <input
                        onChange={onChange}
                        required
                        type="text"
                        class="form-control "
                        id="inputFname"
                        placeholder="First Name"
                        name="firstName"
                      />
                    </div>
                    <div className="col-lg-6">
                      <Label text="Last Name*" />
                      <input
                        onChange={onChange}
                        required
                        type="text"
                        class="form-control"
                        id="inputLname"
                        placeholder="last Name"
                        name="lastName"
                      />
                    </div>
                  </div>
                  <div className="d-flex col-lg-12 space-between signup-input">
                    <div className="col-lg-6 m-r10">
                      <Label text="Email*" />
                      <input
                        onChange={onChange}
                        required
                        type="email"
                        class="form-control"
                        id="inputEmail"
                        placeholder="Email"
                        name="email"
                        onBlur={handleDuplicateEmail}
                      />
                      <span style={{ color: "red" }}>{emailError}</span>
                    </div>
                    <div className="col-lg-6">
                      <Label text="Phone Number*" />
                      <input
                        onChange={onChange}
                        required
                        type="number"
                        class="form-control"
                        id="inputPhoneNumber"
                        placeholder="Phone Number"
                        name="phoneNumber"
                      />
                    </div>
                  </div>
                  <div className="d-flex col-lg-12 space-between signup-input">
                    <div className="col-lg-6 m-r10">
                      <Label text="Address*" />
                      <input
                        onChange={onChange}
                        required
                        type="text"
                        class="form-control "
                        id="inputAdress"
                        placeholder="Address"
                        name="addressLine1"
                      />
                    </div>
                    <div className="col-lg-6">
                      <Label text="Suburb*" />
                      <input
                        onChange={(e) => {
                          setSignUpData((pre) => {
                            return {
                              ...pre,
                              [e.target.name]: e.target.value.replace(
                                /\d+/g,
                                ""
                              ),
                            };
                          });
                        }}
                        required
                        type="text"
                        class="form-control "
                        id="inputSuburb"
                        name="city"
                        value={SignUpData?.city}
                      />
                    </div>
                  </div>
                  <div className="d-flex col-lg-12 space-between signup-input">
                    <div className="col-lg-6 m-r10">
                      <Label text="State*" />
                      <select
                        disabled
                        value="SouthAustralia"
                        name="state"
                        required
                        class="form-control form-select"
                      >
                        <option value="">State</option>
                        <option value="New South Wales ">
                          New South Wales{" "}
                        </option>
                        <option value="Queensland">Queensland </option>
                        <option value="NorthernTerritory">
                          Northern Territory
                        </option>
                        <option value="WesternAustralia">
                          Western Australia
                        </option>
                        <option value="SouthAustralia"> South Australia</option>
                        <option value="Victoria">Victoria</option>
                        <option value="theAustralianCapitalTerritory">
                          the Australian Capital Territory
                        </option>
                        <option value="Tasmania">Tasmania</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <Label text="Post code*" />
                      <input
                        onChange={onChange}
                        required
                        type="text"
                        onInput={
                          "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(..*?)..*/g, '$1');"
                        }
                        class="form-control"
                        id="inputPostCode"
                        placeholder="Post code"
                        name="zipCode"
                        maxLength="4"
                      />
                    </div>
                  </div>
                  <div className="d-flex col-lg-12 space-between signup-input">
                    <div className="col-lg-6 m-r10">
                      <Label text="Nationality*" />
                      <select
                        name="nationality"
                        onChange={onChange}
                        required
                        class="form-control form-select"
                      >
                        {countriesList?.map((_) => (
                          <option value={_?.name?.common}>
                            {_?.name?.common}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <Label text="Password*" />
                      <input
                        onChange={onChange}
                        required
                        type="password"
                        class="form-control "
                        id="inputpasswaord"
                        placeholder="password"
                        name="password"
                      />
                    </div>
                  </div>
                  <div className="d-flex col-lg-12 space-between signup-input">
                    <div className="col-lg-6 m-r10 m-t10">
                      <Label text="Confirm password*" />
                      <input
                        onChange={onChange}
                        required
                        type="password"
                        class="form-control "
                        id="confirmpassword"
                        placeholder="confirm password"
                        name="confirmpassword"
                      />
                    </div>

                    <div className="col-lg-6 ">
                      <button type="submit" className="btn btn-green">
                        {/* <Link
                      to={SignUpData?userRole==="FINANCIAL"?"/MembershipPlan":"dfd"}
                      > */}
                        {SignUpData?.userRole == "VOLUNTEER" ||
                          SignUpData?.userRole == "FINANCIAL"
                          ? "Join now"
                          : "Next"}
                      </button>
                    </div>
                  </div>
                  {/* <Label text="Email*" />
                  <input
                    onChange={onChange}
                    required
                    type="email"
                    class="form-control "
                    id="inputEmail"
                    placeholder="Email"
                    name="email"
                  />
                  <Label text="Address*" />
                  <input
                    onChange={onChange}
                    required
                    type="text"
                    class="form-control "
                    id="inputAdress"
                    placeholder="Address"
                    name="addressLine1"
                  />
                  <Label text="Suburb*" />
                  <input
                    onChange={onChange}
                    required
                    type="text"
                    class="form-control "
                    id="inputSuburb"
                    placeholder="Suburb"
                    name="city"
                  />
                  <Label text="Password*" />
                  <input
                    onChange={onChange}
                    required
                    type="password"
                    class="form-control "
                    id="inputpasswaord"
                    placeholder="password"
                    name="password"
                  />
                  <Label text="Confirm password*" />
                  <input
                    onChange={onChange}
                    required
                    type="password"
                    class="form-control "
                    id="confirmpassword"
                    placeholder="confirm password"
                    name="confirmpassword"
                  /> */}
                </div>
                <div className="col-lg-6">
                  {/* <Label text="Phone Number*" />
                  <input
                    onChange={onChange}
                    required
                    type="text"
                    class="form-control"
                    id="inputPhoneNumber"
                    placeholder="Phone Number"
                    name="phoneNumber"
                  />
                  <Label text="State*" />
                  <select disabled value="SouthAustralia" name="state" required class="form-control form-select">
                    <option value="">State</option>
                    <option value="New South Wales ">New South Wales </option>
                    <option value="Queensland">Queensland </option>
                    <option value="NorthernTerritory">Northern Territory</option>
                    <option value="WesternAustralia">Western Australia</option>
                    <option value="SouthAustralia"> South Australia</option>
                    <option value="Victoria">Victoria</option>
                    <option value="theAustralianCapitalTerritory">the Australian Capital Territory</option>
                    <option value="Tasmania">Tasmania</option>
                  </select>
                  <Label text="Post code*" />
                  <input
                    onChange={onChange}
                    required
                    type="text"
                    class="form-control"
                    id="inputPostCode"
                    placeholder="Post code"
                    name="zipCode"
                  />
                  <Label text="Nationality*" />
                  <select name="nationality" onChange={onChange} required class="form-control form-select">
                    <option value="">Nationality</option>
                    <option value="1">Pakistan</option>
                    <option value="2">Australia</option>
                    <option value="3">India</option>
                    <option value="4">China</option>
                    <option value="5">Indonesia</option>
                  </select> */}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="member">
                    <Link to="/LogIn"> SIGN IN </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
