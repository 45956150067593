import React, { useState, useEffect } from "react";
import InputComponent from "../../commonComponents/Input/InputComponent";
import DatePicker from "../../commonComponents/DatePicker/DatePicker";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const BookingForm = ({
  formData,
  setFormData,
  VehicleData,
  setOpenDialog,
  calculateRent,
  setSteps,
}) => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useEffect(() => {
    setFormData(() => {
      return {
        ...formData,
        nameOfDriver: formData?.formValues?.vehicleBooking[0]
          ? formData?.formValues?.vehicleBooking[0]?.nameOfDriver
          : "",
      };
    });
  }, []);

  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setFormData({ ...formData, ...obj });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "formData");
    if (!formData?.startDate && !formData?.endDate) {
      // toast.error("Please select date");
      Swal.fire("Error", "Please select date", "error");
      return;
    }

    setSteps("2");
    calculateRent();
  };
  return (
    <>
      <div class="w-50">
        <form onSubmit={handleSubmit}>
          <label> Date</label>
          <DatePicker
            range={range}
            setRange={setRange}
            setFormData={setFormData}
            VehicleData={VehicleData?.filter(
              (val) => val?.id === formData?.vehicleId
            )}
          />
          <label>Driver Name</label>
          <InputComponent
            required={true}
            type="text"
            className="form-control"
            id="inputPhoneNumber"
            placeholder="Driver Name"
            onChange={(e) => _setState("nameOfDriver", e.target.value)}
            value={formData?.nameOfDriver}
          />
          <label>Booking Reason</label>
          <select
            required={true}
            onChange={(e) => _setState("bookingReason", e.target.value)}
            class="form-control form-select"
          >
            <option value="">Select booking reason</option>
            <option value="Interstate Travel">Interstate Travel</option>
            <option value="Picnic">Picnic</option>
            <option value="Community Support">Community Support</option>
            <option value="Refugee Support">Refugee Support</option>
            <option value="Loan Car">Loan Car</option>
          </select>
          <label>Emergency phone number</label>
          <InputComponent
            required={true}
            type="tel"
            className="form-control"
            id="inputEmergancyNumber"
            placeholder="Emergency phone number"
            onChange={(e) => _setState("emergencyPhoneNumber", e.target.value)}
          />
          <label>Driver License Number</label>
          <InputComponent
            required={true}
            type="text"
            className="form-control"
            id="driverLicenseNumber"
            placeholder="Driver License Number"
            onChange={(e) => _setState("driverLicenseNumber", e.target.value)}
          />
          <div class="form-check">
            <input
              required
              checked={formData?.isAcceptedTerms}
              class="form-check-input"
              type="checkbox"
              id="defaultCheck1"
              onClick={() => {
                setOpenDialog(true);
              }}
            />
            <label class="form-check-label " for="defaultCheck1">
              <a href="/">Term & Conditions</a>
            </label>
          </div>
          <div className="w-100 text-center">
            <button
              type="submit"
              //</div>onClick={(e) => {
              // if (
              //   formData?.isAcceptedTerms&&
              //   formData?.emergencyPhoneNumber&&
              //   formData?.bookingReason&&
              //   formData?.driverLicenseNumber&&
              //   formData?.startDate&&
              //   formData?.endDate)
              //{
              //e.preventDefault();
              // setSteps("2");
              // calculateRent();
              //}
              // else{
              //   e.preventDefault();
              //   setSteps("1");
              //   toast.error("Please Fill all form fields")
              // }
              //}}
              style={{ width: "46.5%" }}
              className="btn btn-green"
            >
              Book now
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BookingForm;
