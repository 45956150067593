import React, { useState } from 'react';
import DatePicker from '../../commonComponents/DatePicker/DatePicker';

const BookingDates = ({ formData, VehicleData, setFormData, addToCart }) => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  console.log(VehicleData, 'VehicleData');
  return (
    <>
      <div class="w-50">
        <DatePicker
          addToCart={addToCart}
          assetManagement={true}
          range={range}
          setRange={setRange}
          formData={formData}
          setFormData={setFormData}
          VehicleData={VehicleData}
        />
      </div>
    </>
  );
};

export default BookingDates;
