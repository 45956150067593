import React from "react";
import Box from "@mui/material/Box";
import InputComponent from "../../../commonComponents/Input/InputComponent";
// import Button from "@mui/material/Button";
// import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ChangePassword = ({
  handleSubmitPassword,
  passwordData,
  setPasswordData,
}) => {
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setPasswordData({ ...passwordData, ...obj });
  };
  const SubmitData = async (e) => {
    e.preventDefault();
    console.log(passwordData?.newPassword, passwordData?.cPassword);
    if (passwordData?.newPassword != passwordData?.cPassword) {
      // toast.error("Password and Confirm Password should be same");
      Swal.fire(
        "Error",
        "Password and Confirm Password should be same",
        "error"
      );
      return;
    }
    handleSubmitPassword();
  };
  return (
    <>
      <Box className="flex-col w-100 p-t20">
        <form onSubmit={SubmitData}>
          <Box className="flex-col w-50 c-password">
            <label>Old Password </label>
            <InputComponent
              required={true}
              onChange={(e) => _setState("oldPassword", e.target.value)}
              value={passwordData?.oldPassword}
              placeholder="Old Password"
              className="accunt_details_input"
              type="password"
            />
          </Box>
          <Box className="flex-col w-50 c-password">
            <label>New Password </label>
            <InputComponent
              required={true}
              onChange={(e) => _setState("newPassword", e.target.value)}
              value={passwordData?.lastname}
              placeholder="New Password"
              className="accunt_details_input"
              type="password"
            />
          </Box>
          <Box className="flex-col w-50 c-password">
            <label>Confirm Password </label>
            <InputComponent
              required={true}
              onChange={(e) => _setState("cPassword", e.target.value)}
              value={passwordData?.cPassword}
              placeholder="Confirm Password"
              className="accunt_details_input"
              type="password"
            />
          </Box>
          <button
            className="btn btn-green change-password-btn"
            style={{ marginTop: "2%" }}
            type="submit"
          >
            Change Password
          </button>
        </form>
      </Box>
    </>
  );
};

export default ChangePassword;
