import React from 'react';
import InputComponent from '../../../commonComponents/Input/InputComponent';
import Label from '../../../commonComponents/Label/Label';

const BookingFormCart = ({ formData, setSteps, setFormData }) => {
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setFormData({ ...formData, ...obj });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div class="w-100 d-flex justify-content">
        <form class="w-50 p-a20" onSubmit={handleSubmit}>
          <Label text="Email" />
          <InputComponent
            required={true}
            type="email"
            className="form-control"
            placeholder="Email"
            onChange={(e) => _setState('email', e.target.value)}
            value={formData?.email}
          />
          <Label text="First Name" />
          <InputComponent
            required={true}
            type="text"
            className="form-control"
            placeholder="First name"
            onChange={(e) => _setState('firstName', e.target.value)}
            value={formData?.firstName}
          />
          <Label text="Last Name" />
          <InputComponent
            required={true}
            type="text"
            className="form-control"
            placeholder="Last Name"
            onChange={(e) => _setState('lastName', e.target.value)}
            value={formData?.lastName}
          />
          <Label text="Address" />
          <InputComponent
            required={true}
            type="text"
            className="form-control"
            placeholder="Address"
            onChange={(e) => _setState('address', e.target.value)}
            value={formData?.address}
          />
          <Label text="Phone Number" />
          <InputComponent
            required={true}
            type="number"
            className="form-control"
            placeholder="Phone Number"
            onChange={(e) => _setState('phoneNumber', e.target.value)}
            value={formData?.phoneNumber}
          />
          <Label text="Booking Reason" />
          <select required={true} onChange={(e) => _setState('bookingReason', e.target.value)} class="form-control form-select">
            <option value="">Select booking reason</option>
            <option value="Interstate Travel">Interstate Travel</option>
            <option value="Picnic">Picnic</option>
            <option value="Community Support">Community Support</option>
            <option value="Refugee Support">Refugee Support</option>
            <option value="Loan Car">Loan Car</option>
          </select>
          <div className="w-100 text-center">
            <button onClick={() => setSteps('2')} type="submit" style={{ width: '46.5%' }} className="btn btn-green">
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BookingFormCart;
