export const getToken = () => {
  let userdata = localStorage.getItem('userData');
  userdata = JSON.parse(userdata);
  return userdata?.usertoken;
};

export const removeToken = () => {
  localStorage.clear();
  window.location = '/login';
};
