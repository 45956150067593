import React from "react";

import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import AppBar from "../../commonComponents/AppBar";

import PaymentCard from "./PaymentCard";

import { loadStripe } from "@stripe/stripe-js";

import { Store } from "../../StoreContext";

import { Elements } from "@stripe/react-stripe-js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getPlanActiveEndDateYear } from "../../utils/Helpers";
import {  appConfig } from "../../utils/Constants";

const stripePromise = loadStripe(appConfig.stripeKey);

const Payment = () => {
  let { state } = useLocation();
  let navigate = useNavigate();

  // let date = new Date();
  // const store = Store();
  const priceCards = [
    {
      head: state?.packageName,
      text: `Access until June 30, ${getPlanActiveEndDateYear()}`,
      price: state?.packagePrice,
      btn: "Become a member",
    },
  ];

  return (
    <>
      <>
        <AppBar />
        <section className="p-t120 membership-plan payment">
          <div className="wt-bnr-inr  p-t50">
            <div className="container-lg">
              <div className="banner-nav">
                <Link to="/SignUp"> Create account </Link>
                <i className="	fa fa-angle-left"></i>
                <Link to="/MembershipPlan"> Choose membership plan </Link>
                <i className="	fa fa-angle-left"></i>
                <span className="green-text">Payment</span>
              </div>
            </div>
          </div>

          <div className="container-lg">
            <div className="row">
              <div className="col-xl-6 col-sm-12 col-12">
                <div className="pay-contan">
                  <div className="long-form signUp payment-form">
                    <div className="container-lg">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(-1)}
                      >
                        <ArrowBackIcon />
                        <div>back</div>
                      </div>
                      <div className="form-heading ">
                        <h4>Payment</h4>
                      </div>

                      <div className="row">
                        {/* <div className="col-lg-12">
                          <span className="SignUpAs">Choose payment method</span>
                        </div> */}
                        <div className="col-lg-12">
                          <Elements stripe={stripePromise}>
                            <PaymentCard state={state} />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12">
                {priceCards.map((value, index) => {
                  return (
                    <>
                      <div
                        className="membership-cards payment-card"
                        key={index}
                      >
                        <div class="card text-center">
                          <div class="card-header shadow">{value.head}</div>
                          <div class="card-body">
                            <p class="card-title">{value.text}</p>

                            <div className="card-price">
                              {" "}
                              {state.packagePrice}${" "}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </>
                  );
                })}
                <div className="change-package">
                  <Link to="/MembershipPlan">Change package</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Payment;
