import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputComponent from "../../../commonComponents/Input/InputComponent";
import Label from "../../../commonComponents/Label/Label";
import ButtonComponent from "../../../commonComponents/Button/ButtonComponent";
import { states, nationalities } from "../../../utils/Constants";

import axios from "../../../axiosInstance";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AccountDetails = ({ UserData, setUserData, setState }) => {
  const [countriesList, setCountriesList] = useState();
  useEffect(() => {
    setUserData({
      email: UserData?.email,
      firstName: UserData?.profile?.firstName,
      lastName: UserData?.profile?.lastName,
      phoneNumber: UserData?.profile?.phoneNumber,
      addressLine1: UserData?.profile?.addressLine1,
      city: UserData?.profile?.city,
      state: "SouthAustralia",
      zipCode: UserData?.profile?.zipCode,
      nationality: UserData?.profile?.nationality,
      numberOfChildren: UserData?.profile?.numberOfChildren,
      childernAges: UserData?.profile?.childernAges,
      spouseEmailAddress: UserData?.profile?.spouseEmailAddress,
      spouseName: UserData?.profile?.spouseName,
      userRole: UserData?.userRole,
    });
    CountriesList();
  }, []);
  const CountriesList = async () => {
    try {
      let response = await axios.get("https://restcountries.com/v3.1/all");
      setCountriesList(response?.data);
    } catch (error) {
      console.trace(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      firstName: UserData?.firstName,
      lastName: UserData?.lastName,
      phoneNumber: UserData?.phoneNumber,
      addressLine1: UserData?.addressLine1,
      city: UserData?.city,
      state: "SouthAustralia",
      zipCode: UserData?.zipCode,
      nationality: UserData?.nationality,
      spouseName: UserData?.spouseName,
      spouseEmailAddress: UserData?.spouseEmailAddress,
      userRole: UserData?.userRole,
      numberOfChildren: parseInt(UserData?.numberOfChildren),
      childernAges: UserData?.childernAges,
    };
    let response;
    try {
      response = await axios.patch("/user/updateProfile", data);
      if (response?.data?.type == "success") {
        // toast.success("Data Saved Succesfully");
        Swal.fire("Done", "Data Saved Succesfully", "success");
      }
      console.log(response.data?.type);
    } catch (e) {
      // toast.error(e.response.data.message);
      Swal.fire("Error", e.response.data.message, "error");
    }
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const id = e.target.id;
    const value = e.target.value;
    console.log(id, "id");
    console.log(name, "name");
    console.log(name[id], "name");

    console.log(UserData?.childernAges, "h");
    const newIds = UserData?.childernAges; //copy the array
    newIds[id] = value; //execute the manipulations
    //set the new state
    console.log(newIds, "newIds");
    setUserData((pre) => {
      return {
        ...pre,
        childernAges: newIds,
      };
    });
  };
  console.log(countriesList, "UserData");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "2%",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box className="input_container">
            <Box className="flex-col w-100">
              <label>First Name* </label>
              <InputComponent
                required={true}
                onChange={(e) => setState("firstName", e.target.value)}
                value={UserData?.firstName}
                placeholder="First Name"
                className="accunt_details_input"
              />
            </Box>
            <Box className="flex-col w-100">
              <label>Last Name* </label>
              <InputComponent
                required={true}
                onChange={(e) => setState("lastName", e.target.value)}
                value={UserData?.lastName}
                placeholder="Last Name"
                className="accunt_details_input"
              />
            </Box>
          </Box>
          <Box className="input_container">
            <Box className="flex-col w-100">
              <label>Phone* </label>
              <InputComponent
                required={true}
                onChange={(e) => setState("phoneNumber", e.target.value)}
                value={UserData?.phoneNumber}
                placeholder="Phone"
                className="accunt_details_input"
              />
            </Box>
            <Box className="flex-col w-100">
              <label>Email* </label>
              <InputComponent
                required={true}
                onChange={(e) => setState("email", e.target.value)}
                value={UserData?.email}
                type="email"
                placeholder="Email"
                className="accunt_details_input"
                disabled={true}
              />
            </Box>
          </Box>
          <Box className="input_container">
            <Box className="flex-col w-100">
              <label>Address* </label>
              <InputComponent
                required={true}
                onChange={(e) => setState("addressLine1", e.target.value)}
                value={UserData?.addressLine1}
                placeholder="Address"
                className="accunt_details_input"
              />
            </Box>
            <Box className="flex-col w-100">
              <label>State* </label>
              <select
                disabled
                value="4"
                name="state"
                required
                class="form-control form-select"
              >
                {states.map((_) => (
                  <>
                    <option value={_?.id}>{_?.text}</option>
                  </>
                ))}
              </select>
            </Box>
          </Box>
          <Box className="input_container">
            <Box className="flex-col w-100">
              <label>Suburb* </label>
              <InputComponent
                required={true}
                onChange={(e) => setState("city", e.target.value)}
                value={UserData?.city}
                placeholder="Suburb"
                className="accunt_details_input"
              />
            </Box>
            <Box className="flex-col w-100">
              <label>Post Code* </label>
              <InputComponent
                required={true}
                onChange={(e) => setState("zipCode", e.target.value)}
                value={UserData?.zipCode}
                type="text"
                placeholder="Post Code"
                className="accunt_details_input"
              />
            </Box>
          </Box>
          <Box className="input_container">
            <Box className="flex-col w-100">
              <label>Nationality* </label>
              <select
                name="nationality"
                onChange={(e) => setState("nationality", e.target.value)}
                required
                class="form-control form-select"
                value={UserData?.nationality}
              >
                {countriesList?.map((_) => (
                  <option value={_?.name?.common}>{_?.name?.common}</option>
                ))}
              </select>
            </Box>
            {UserData?.userRole == "FINANCIAL" ? (
              <Box className="flex-col w-100">
                <label>Spouse Name*</label>
                <InputComponent
                  required={true}
                  onChange={(e) => setState("spouseName", e.target.value)}
                  value={UserData?.spouseName}
                  type="text"
                  placeholder="Spouse Name"
                  className="accunt_details_input"
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          {UserData?.userRole == "FINANCIAL" ? (
            <>
              <Box className="input_container">
                <Box className="flex-col w-100">
                  <label>Spouse Email Address*</label>
                  <InputComponent
                    required={true}
                    onChange={(e) =>
                      setState("spouseEmailAddress", e.target.value)
                    }
                    value={UserData?.spouseEmailAddress}
                    type="email"
                    placeholder="Spouse Email Address"
                    className="accunt_details_input"
                  />
                </Box>

                <Box className="flex-col w-100">
                  <label>User Role*</label>
                  <InputComponent
                    required={true}
                    onChange={(e) => setState("userRole", e.target.value)}
                    value={UserData?.userRole}
                    type="text"
                    placeholder="User Role"
                    className="accunt_details_input"
                    disabled={true}
                  />
                </Box>
              </Box>
              <Box className="input_container">
                <Box className="flex-col w-100">
                  <label>No of Children*</label>
                  <InputComponent
                    required={true}
                    onChange={(e) =>
                      setState("numberOfChildren", e.target.value)
                    }
                    value={UserData?.numberOfChildren}
                    type="number"
                    placeholder="No of Children"
                    className="accunt_details_input"
                    disabled={true}
                  />
                </Box>
                {console.log(
                  UserData?.childernAges,
                  "UserData?.profile?.numberOfChildren"
                )}
                <Box className="flex-col w-100">
                  {[
                    ...Array(
                      UserData?.numberOfChildren
                        ? UserData?.numberOfChildren
                        : 0
                    ),
                  ].map((elementInArray, index) => (
                    <>
                      <Label text="Age" />
                      <InputComponent
                        required={true}
                        type="number"
                        className="form-control"
                        id={index}
                        name="childernAges"
                        placeholder="Enter age"
                        onChange={handleChange}
                        defaultValue={UserData?.childernAges[index]}
                      />
                    </>
                  ))}
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}

          <div className="m-lr15">
            <ButtonComponent className="btn btn-green" text="Update" />
          </div>
        </form>
      </Box>
    </>
  );
};

export default AccountDetails;
