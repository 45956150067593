import React, { useMemo, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from "../../axiosInstance";

import useResponsiveFontSize from "../../commonComponents/useResponsiveFontSize";
import CustomAlert from "../../commonComponents/Modal/CustomAlert";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import Swal from "sweetalert2";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const DonateUsPaymentCard = ({ formData, setFormData, setSteps, onFailedPayment }) => {
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  let clientSecret;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    //Client Sectet Get from Server:
    let data = {
      amount: formData?.amount,
      email: formData?.email,
    };
    let response;
    try {
      response = await axios.post("/payment/createDonatePayment", data);
      if (response?.data?.type == "success") {
        // toast.success(response?.data?.message);
        Swal.fire("Done", response?.data?.message, "success");
        clientSecret = response?.data?.data?.clientSecret;
      }
    } catch (e) {
      // toast.error(e.response.data.message);
      Swal.fire("Error", e?.classNameresponse?.data?.message, "error");
    }

    const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: elements.getElement(CardElement) },
    });
    console.log(paymentIntent?.status);
    console.log(paymentIntent);
    debugger
    if (paymentIntent?.status === "succeeded") {
      let data = {
        name: formData?.name,
        description: "Donation",
        email: formData?.email,
        phoneNumber: formData?.phoneNumber,
        amount: parseInt(formData?.amount),
      };

      try {
        let response = await axios.post("donation/add", data);
        if (response?.data?.type == "success") {
          // toast.success(response?.data?.message);
          Swal.fire("Done", response?.data?.message, "success");

          setFormData({});
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            window.location = "/AboutPaasa";
          }, 3000);
        }
      } catch (e) {
        // toast.error(e?.response?.data?.message);
        Swal.fire("Error", e?.response?.data?.message, "error");
      }

      //Save User plan Activation api call
    } else if (error?.message) {
      onFailedPayment();
      setSteps("1");
      Swal.fire("Payment Failed", error?.message, "error");
    }
    else {
      onFailedPayment();
      setSteps("1");
      Swal.fire("Error", paymentIntent?.cancellation_reason, "error");
    }
  };
  return (
    <>
      {showAlert ? <CustomAlert /> : <></>}
      <form type="submit" onSubmit={handleSubmit}>
        <label className="w-100 parent-label">
          Card details
          <CardElement
            options={options}
            onReady={() => {
              console.log("CardElement [ready]");
            }}
            onChange={(event) => {
              console.log("CardElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardElement [blur]");
            }}
            onFocus={() => {
              console.log("CardElement [focus]");
            }}
          />
        </label>
        <button type="submit" disabled={!stripe}>
          Pay
        </button>
      </form>
    </>
  );
};

export default DonateUsPaymentCard;
