import { Validators, formValidator } from '../utils/Validate';

function SignUp(data) {
  const valFuncArr = [
    () => Validators.nullValidator('studyingCourse', data?.formData?.studyingCourse),
    () => Validators.nullValidator('spouseName', data?.formData?.spouseName),
    () => Validators.nullValidator('spouseEmailAddress', data?.formData?.spouseEmailAddress),
    //() => Validators.nullValidator('date', data?.formData?.date),
    // () => (data?.password ? Validators.passwordValidator('Password', data['password']) : null),
    // () => Validators.phoneLengthValidator('Numéro de téléphone', data['phone']),
    // () => Validators.minlengthValidator(`Nom de l'entreprise`, data['company_name']),
    // () => Validators.minlengthValidator('Prénom', data['first_name']),
    // () => Validators.minlengthValidator('Nom de famille', data['last_name']),
  ];

  const validated = formValidator(valFuncArr);

  return validated;
}
function SignUpSingleAndStudent(data) {
  console.log(data, 'data');
  const valFuncArr = [
    () => Validators.nullValidator('studyingCourse', data?.formData?.studyingCourse),
    () => Validators.nullValidator('spouseName', data?.formData?.studying),
    () => Validators.nullValidator('spouseEmailAddress', data?.formData?.collegename),
    //() => Validators.nullValidator('date', data?.formData?.date),
    // () => (data?.password ? Validators.passwordValidator('Password', data['password']) : null),
    // () => Validators.phoneLengthValidator('Numéro de téléphone', data['phone']),
    // () => Validators.minlengthValidator(`Nom de l'entreprise`, data['company_name']),
    // () => Validators.minlengthValidator('Prénom', data['first_name']),
    // () => Validators.minlengthValidator('Nom de famille', data['last_name']),
  ];

  const validated = formValidator(valFuncArr);

  return validated;
}
function ContactUs(data) {
  const valFuncArr = [
    () => Validators.nullValidator('Name', data?.UserData?.name),
    () => Validators.emailValidator('Email', data?.UserData?.email),
    () => Validators.nullValidator('Subject', data?.UserData?.subject),
    () => Validators.nullValidator('Message', data?.UserData?.message),
    //() => Validators.nullValidator('date', data?.formData?.date),
    // () => (data?.password ? Validators.passwordValidator('Password', data['password']) : null),
    // () => Validators.phoneLengthValidator('Numéro de téléphone', data['phone']),
    // () => Validators.minlengthValidator(`Nom de l'entreprise`, data['company_name']),
    // () => Validators.minlengthValidator('Prénom', data['first_name']),
    // () => Validators.minlengthValidator('Nom de famille', data['last_name']),
  ];

  const validated = formValidator(valFuncArr);

  return validated;
}

export { SignUp, ContactUs, SignUpSingleAndStudent };
