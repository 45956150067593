import React from "react";

const InputComponent = ({
  type,
  className,
  id,
  name,
  placeholder,
  required,
  onChange,
  value,
  disabled,
  defaultValue,
  min,
  onInput,
  maxLength,
  pattern,
}) => {
  return (
    <>
      <input
        type={type}
        min={min || ""}
        className={className}
        onInput={onInput || ""}
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        maxLength={maxLength || ""}
        //pattern={pattern}
        // onInvalid={e => {
        //   if(type=="tel"){
        //     e.target.setCustomValidity('Please enter 12 digits phone number')
        //   }
        //    }}
        title={type == "tel" ? "123456789" : ""}
        //  onInput={e => e.target.setCustomValidity('')}
      />
    </>
  );
};

export default InputComponent;
