import React, { useState } from "react";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import BookingForm from "../../BookingVehicale/BookingForm";

import Bookcharges from "../../BookingVehicale/Bookcharges";
import BookingEventPayment from "./BookingEventPayment";

import DialogBox from "../../../commonComponents/DialogBox/DialogBox";

import { getToken } from "../../../utils/Auth";

import { useNavigate, useLocation } from "react-router-dom";

import { BootstrapDialog } from "../../../utils/Constants";
import { BootstrapDialogTitle } from "../../../utils/Constants";
import { useEffect } from "react";
import axios from "../../../axiosInstance";

import { Button, ButtonGroup } from "@mui/material";
import { width } from "@mui/system";
import "./eventCards.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const buttonStyles: React.CSSProperties = {
  height: "26px",
  width: "45px",
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const priceTypes = {
  Custom: "Custom",
  Free: "Free",
  Fixed: "Fixed",
};
const ticketTypes = {
  Single: "Single",
  Group: "Group",
};
const EventCards = ({
  VehicleData,
  formData,
  setFormData,
  getUserInfo,
  getVehicles,
  calculateRent,
  BillingData,
  ProceedToPayment,
  Steps,
  setSteps,
  Open,
  setOpen,
  vehicleFormData,
  setVehicleFormData,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [openDialog2, setOpenDialog2] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [billingData, setBillingData] = useState({});
  const [freeTickets, setFreeTickets] = useState(0);
  const [reservedTickets, setReservedTickets] = useState(0);
  const [result, setResult] = useState();
  const [totalAmount, setTotalAmount] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  console.log({ state: location.state }, "state ha ye");
  const token = getToken();

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickOpenDialog2 = () => {
    setOpenDialog2(true);
  };

  const [counters, setCounters] = useState(
    new Array(location.state.customTickets?.length).fill(0)
  );

  console.log({ counters });
  useEffect(() => {
    handleClickOpen("vehicleId", 2);
    if (
      location.state.ticketType === ticketTypes.Group &&
      location.state.priceType === priceTypes.Free
    )
      setFreeTickets(location.state.fixedTickets);
    else if (
      location.state.ticketType === ticketTypes.Group &&
      location.state.priceType === priceTypes.Fixed
    )
      setReservedTickets(location.state.fixedTickets);
  }, []);

  const handleCloseDialog = (props) => {
    console.log(props, "props");
    if (props == "agree") {
      setFormData({
        ...formData,
        isAcceptedTerms: true,
      });
    }
    if (props == "disagree") {
      setFormData({
        ...formData,
        isAcceptedTerms: false,
      });
    }
    if (props == "cancel_booking") {
      setFormData({});
    }
    setOpenDialog(false);
  };

  const onHandleNext = () => {
    setOpen2(true);
  };

  const handleCloseDialog2 = () => {};

  const handleClickOpen = (key, id, values) => {
    console.log(values);
    if (token) {
      setOpen(true);
      setSteps("1");
      const obj = {};
      obj[key] = id;
      setFormData({ ...formData, ...obj, formValues: values });
    } else {
      navigate("/LogIn");
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSteps("1");
    setFormData({});
    navigate("/NewsAndEvents");
  };

  const proceedToPaymendHandler = async (step3 = false) => {
    const payload = {
      purchasedTicket: counters.reduce((a, b) => a + b, 0), // taking sum of all the elements
      customTickets: location.state.customTickets.map((customTicket, index) => {
        return {
          title: customTicket.title,
          purchasedTicket: counters[index],
        };
      }),
      ticketId: location.state.eventId,
    };

    // console.log(payload, "payload");

    const payloadForFree = {
      purchasedTicket: freeTickets,
      customTickets: [],
      ticketId: location.state.eventId,
    };

    const payloadForFreeGroup = {
      purchasedTicket: location.state.fixedTickets,
      customTickets: [],
      ticketId: location.state.eventId,
    };

    const payloadForFixed = {
      purchasedTicket: reservedTickets,
      customTickets: [],
      ticketId: location.state.eventId,
    };

    const payloadForFixedGroup = {
      purchasedTicket: location.state.fixedTickets,
      customTickets: [],
      ticketId: location.state.eventId,
    };

    let response, response2;

    try {
      response = await axios.post(
        "/eventMangement/ticketBooking/checkAvailabilityGetCharges",
        location.state.priceType === priceTypes.Free &&
          location.state.ticketType === ticketTypes.Group
          ? payloadForFreeGroup
          : location.state.priceType === priceTypes.Free
          ? payloadForFree
          : location.state.priceType === priceTypes.Fixed &&
            location.state.ticketType === ticketTypes.Group
          ? payloadForFixedGroup
          : location.state.priceType === priceTypes.Fixed
          ? payloadForFixed
          : payload
      );
      debugger;
      if (response) {
        setResult(response);
        setOpen2((state) => !state);
      }
      if (
        response?.data?.type == "success" &&
        location.state.priceType === priceTypes.Free
      ) {
        console.log(payload, "....");
        response2 = await axios.post(
          "/eventMangement/ticketBooking/bookTicket",
          location.state.priceType === priceTypes.Free
            ? {
                ...payloadForFree,
                paymentStatus: "SUCCESS",
              }
            : location.state.priceType === priceTypes.Fixed
            ? { ...payloadForFixed, paymentStatus: "SUCCESS" }
            : {
                ...payload,
                paymentStatus: "SUCCESS",
              }
        );
        if (response2?.data?.type === "success") {
          // toast.success("Data Saved Successfully");
          Swal.fire("Done", "Data Saved Successfully", "success");

          if (location.state.priceType === priceTypes.Free) {
            navigate("/NewsAndEvents");
            return;
          }
        }
      }
      console.log(response, "response");
      setBillingData({
        totalPriceWithSecurity: response?.data?.data?.charges,
      });

      if (step3 == true) {
        setSteps("3");
      }

      if (response?.data?.type == "error") {
        // toast.error(response?.data?.message);
        Swal.fire("Error", response?.data?.message, "error");
      }
    } catch (e) {
      console.trace(e);
      // toast.error(e?.response?.data?.message);
      Swal.fire("Error", e?.response?.data?.message, "error");
    }
  };
  console.log({ billingData });
  const calculateAmount = () => {
    let val = 0;
    if (location?.state?.customTickets.length > 0) {
      location.state.customTickets.map((customTicket, index) => {
        val += customTicket.price * counters[index];
      });
    } else {
      val = location.state.price * reservedTickets;
    }
    setTotalAmount(val);
  };

  useEffect(() => {
    calculateAmount();
  }, [open2]);

  return (
    <>
      <BootstrapDialog open={open2}>
        <DialogBox
          handleClickOpenDialog={handleClickOpenDialog2}
          handleCloseDialog={handleCloseDialog2}
          openDialog={openDialog2}
          setOpenDialog={setOpenDialog2}
        />
        <div className="long-form ">
          <div className="container-lg">
            <div className="form-heading mt-3">
              <h4>Payment Summary</h4>
            </div>
            {location.state.priceType === priceTypes.Free && (
              <table align="center" id="reserveEventTable">
                <tr>
                  <th>Ticket Type</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>

                <tr>
                  <td>Free</td>
                  <td>0$</td>
                  {location.state.ticketType === ticketTypes.Group ? (
                    <td> {location.state.fixedTickets} </td>
                  ) : (
                    <td>{location.state.fixedTickets}</td>
                  )}
                  <td>{result?.data?.data?.charges}$</td>
                </tr>
              </table>
            )}
            {location.state.priceType === priceTypes.Fixed && (
              <table align="center" id="reserveEventTable">
                <tr>
                  <th>Ticket Type</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>

                <tr>
                  <td>Fixed</td>
                  <td>{location.state.price}$</td>
                  <td>{reservedTickets}</td>
                  <td>{totalAmount}$</td>
                </tr>
              </table>
            )}
            <div class="row justify-content">
              <div>
                {location.state.priceType === priceTypes.Custom && (
                  <table align="center" id="reserveEventTable">
                    <tr>
                      <th>Ticket Type</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                    </tr>
                    {location.state.customTickets.map((customTicket, index) => {
                      return (
                        <tr>
                          <td>{customTicket.title}</td>
                          <td>{customTicket.price}$</td>
                          <td>{counters[index]}</td>
                          <td>{counters[index] * customTicket.price}$</td>
                        </tr>
                      );
                    })}
                  </table>
                )}
              </div>
            </div>
            <div className="row justify-content center mt-4">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <h6>Total: </h6>
                <h6 style={{ marginLeft: "15px" }}> {totalAmount}$</h6>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <h6>Discount According to Membership: </h6>
                <h6 style={{ marginLeft: "15px" }}>
                  {result?.data?.data?.discount}$
                </h6>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <h5>Total Charges: </h5>
                <h5 style={{ marginLeft: "15px" }}>
                  {result?.data?.data?.charges}$
                </h5>
              </div>
            </div>
          </div>
          <Button
            style={{
              display: "flex",
              width: "50%",
              marginTop: "30px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
            variant="contained"
            color="success"
            onClick={() => proceedToPaymendHandler(true)}
          >
            Proceed To Payment
          </Button>
        </div>
      </BootstrapDialog>
      <BootstrapDialog
        //onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Open}
      >
        <DialogBox
          handleClickOpenDialog={handleClickOpenDialog}
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />

        {Steps === "1" ? (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            ></BootstrapDialogTitle>

            <div className="long-form ">
              <div className="container-lg">
                <div className="form-heading">
                  <h4>Reserve an Event</h4>
                </div>
                {location.state.priceType === priceTypes.Free && (
                  <table align="center" id="reserveEventTable">
                    <tr>
                      <th>Ticket Type</th>

                      <th>Quantity</th>
                    </tr>

                    <tr>
                      <td>Free</td>
                      {location.state.ticketType === ticketTypes.Group ? (
                        <td> {location.state.fixedTickets} </td>
                      ) : (
                        <td>
                          {" "}
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                            fullWidth={false}
                          >
                            <Button
                              onClick={() => {
                                if (freeTickets > 0)
                                  setFreeTickets(freeTickets - 1);
                              }}
                              style={buttonStyles}
                            >
                              -
                            </Button>
                            <Button style={buttonStyles}>{freeTickets}</Button>
                            <Button
                              onClick={() => {
                                setFreeTickets(freeTickets + 1);
                              }}
                              style={buttonStyles}
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </td>
                      )}
                    </tr>
                  </table>
                )}
                {location.state.priceType === priceTypes.Fixed && (
                  <table align="center" id="reserveEventTable">
                    <tr>
                      <th>Ticket Type</th>
                      <th>Price</th>
                      <th>Quantity</th>
                    </tr>

                    <tr>
                      <td>Fixed</td>
                      <td>
                        ${location.state.price * location.state.fixedTickets}
                      </td>
                      {location.state.ticketType === ticketTypes.Group ? (
                        <td> {location.state.fixedTickets} </td>
                      ) : (
                        <td>
                          {" "}
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                            fullWidth={false}
                          >
                            <Button
                              onClick={() => {
                                if (reservedTickets > 0)
                                  setReservedTickets(reservedTickets - 1);
                              }}
                              style={buttonStyles}
                            >
                              -
                            </Button>
                            <Button style={buttonStyles}>
                              {reservedTickets}
                            </Button>
                            <Button
                              onClick={() => {
                                setReservedTickets(reservedTickets + 1);
                              }}
                              style={buttonStyles}
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </td>
                      )}
                    </tr>
                  </table>
                )}
                <div class="row justify-content">
                  <div>
                    {location.state.priceType === priceTypes.Custom && (
                      <table align="center" id="reserveEventTable">
                        <tr>
                          <th>Ticket Type</th>
                          <th>Price</th>
                          <th>Quantity</th>
                        </tr>
                        {location.state.customTickets.map(
                          (customTicket, index) => {
                            return (
                              <tr>
                                <td>{customTicket.title}</td>
                                <td>${customTicket.price}</td>
                                <td>
                                  {" "}
                                  <ButtonGroup
                                    variant="outlined"
                                    aria-label="outlined button group"
                                    fullWidth={false}
                                  >
                                    <Button
                                      onClick={() => {
                                        const result = [...counters];
                                        if (result[index] > 0) result[index]--;

                                        setCounters(result);
                                      }}
                                      style={buttonStyles}
                                    >
                                      -
                                    </Button>
                                    <Button style={buttonStyles}>
                                      {counters[index]}
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        const result = [...counters];
                                        result[index]++;
                                        setCounters(result);
                                      }}
                                      style={buttonStyles}
                                    >
                                      +
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </table>
                    )}
                    <Button
                      style={{
                        display: "flex",
                        width: "50%",
                        marginTop: "30px",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                      variant="contained"
                      color="success"
                      onClick={proceedToPaymendHandler}
                    >
                      Next
                    </Button>
                  </div>

                  <DialogActions></DialogActions>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {Steps === "3" ? (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => setSteps("1")}
            ></BootstrapDialogTitle>

            <BookingEventPayment
              BillingData={billingData}
              formData={formData}
              ProceedToPayment={ProceedToPayment}
              getVehicles={getVehicles}
              setSteps={setSteps}
              setOpen={setOpen}
              freeTickets={freeTickets}
              fixedTickets={reservedTickets}
              itemCounters={counters}
            />
            <div className="cnl-contain">
              <div className="container">
                <div className="row">
                  <div style={{ marginLeft: "3.5%" }} className="col-12">
                    <DialogActions>
                      <button onClick={handleClose} className="btn  cancel-btn">
                        Cancel Booking
                      </button>
                    </DialogActions>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </BootstrapDialog>
    </>
  );
};

export default EventCards;
