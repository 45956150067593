// import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import CarRentalIcon from "@mui/icons-material/CarRental";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const sidemenu = [
  {
    text: "Account Details",
    icon: <PersonIcon />,
    id: 1,
  },
  {
    text: "Event Tickets",
    icon: <CarRentalIcon />,
    id: 2,
  },
  {
    text: "Change Password",
    icon: <SettingsIcon />,
    id: 3,
  },
  {
    text: "Logout",
    icon: <LogoutIcon />,
    id: 4,
  },
];

export const profession = [
  "accountant",
  "actor",
  "actress",
  "air traffic controller",
  "architect",
  "artist",
  "attorney",
  "banker",
  "bartender",
  "barber",
  "bookkeeper",
  "builder",
  "businessman",
  "businesswoman",
  "businessperson",
  "butcher",
  "carpenter",
  "cashier",
  "chef",
  "coach",
  "dental hygienist",
  "dentist",
  "designer",
  "developer",
  "dietician",
  "doctor",
  "economist",
  "editor",
  "electrician",
  "engineer",
  "farmer",
  "filmmaker",
  "fisherman",
  "flight attendant",
  "jeweler",
  "judge",
  "lawyer",
  "mechanic",
  "musician",
  "nutritionist",
  "nurse",
  "optician",
  "painter",
  "pharmacist",
  "photographer",
  "physician",
  "physician's assistant",
  "pilot",
  "plumber",
  "police officer",
  "politician",
  "professor",
  "programmer",
  "psychologist",
  "receptionist",
  "salesman",
  "salesperson",
  "saleswoman",
  "secretary",
  "singer",
  "surgeon",
  "teacher",
  "therapist",
  "translator",
  "undertaker",
  "veterinarian",
  "videographer",
  "waiter",
  "waitress",
  "writer",
];

export const states = [
  {
    text: "New South Wales",
    id: "0",
  },
  {
    text: "Queensland",
    id: "1",
  },
  {
    text: "Northern Territory",
    id: "2",
  },
  {
    text: "Western Australia",
    id: "3",
  },
  {
    text: "South Australia",
    id: "4",
  },
  {
    text: "Victoria",
    id: "5",
  },
  {
    text: "the Australian Capital Territory",
    id: "6",
  },
  {
    text: "Tasmania",
    id: "7",
  },
];

export const nationalities = [
  {
    text: "",
    id: "0",
  },
  {
    text: "Pakistan",
    id: "1",
  },
  {
    text: "Australia",
    id: "2",
  },
  {
    text: "India",
    id: "3",
  },
  {
    text: "China",
    id: "4",
  },
  {
    text: "Indonesia",
    id: "5",
  },
];
///
export const getTotal = (array) => {
  return array
    .map((item) => item.totalPriceWithSecurity)
    .reduce((prev, curr) => prev + curr, 0);
};
///
export const kRegExpEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//const kRegExpPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const kRegExpPassword =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const kRegExpPhone =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const kRegExpPhonefrance = /^0{1}[\d]{9}$/;
export const kRegExpUserName = /^[a-zA-Z0-9_\.]+$/;
/////
export const aboutPassaMainParagraphOne = `PAASA is a not-for-profit community organisation that represents the Pakistani community in the multicultural South Australian society. It is a non-political and non-religious platform that provides every opportunity for the community members to celebrate their social and cultural values. We organise different events/festivals and gatherings to celebrate and showcase our unique cultural diversity to the other multicultural community members. Besides the social and cultural representation of the Pakistani community, we also organise a magnificent event on Australia Day every year to celebrate this national day in a unique style to show our sagacity and belongingness to this country.`;

export const aboutPassaMainParagraphTwo = `We closely work with the government department of multicultural affairs, multicultural minister office, not for profit organisations and other multicultural associations and community organizations to build social cohesion and a general understanding of cultural, social and religious differences through the continuous engagement of the community. With the help of the government multicultural department and other community organisations, we provide the required assistance to the community members especially the new immigrants who face many challenges after their arrival in the new place.`;

export const aboutPassaMainParagraphThree = `We provide different services to the new migrants from logistics support to job readiness training and programs to prepare them to overcome the difficulties they may face. Linguistic barrier, social and cultural differences and employment issues are some common problems faced by new immigrants. We organise airport pickups, the arrangement of the initial stay, job-ready workshops and social gatherings for the new migrant to help them integrate with the larger community.`;

export const aboutPassaMainParagraphFour = `PAASA liaise with SACA and SANFL to organise cricket and footy camps for the Pakistani youth. Our Woolworth Cricket Blast camp with the help of SACA is a very famous program where young kids learn cricket and play the game at different levels. SANFL footy camp is another program in which young girls and boys learn this game and register for advance level programs. PAASA also arrange Tape Ball Cricket Tournament every year for the community in which 12 to 15 teams play this sensational tournament.`;

export const aboutPassaMainParagraphFive = `Our main purpose is to celebrate together our rich cultural, social and religious values allowing multicultural communities to learn about Pakistani culture and customs. Our focus is to integrate the Pakistani community with the Australian way of life and create respect for the Australian values while keeping our own Muslim and Cultural identity intact.`;

export const aboutPassaMissionAndVisionParaOne = `PAASA mission is to promote Pakistani culture and values within an integrated and connected Australian community and continue to be a part of multicultural and ethnic society of South Australia.`;

export const aboutPassaMissionAndVisionParaTwo = `Since then PAASA has been representing Pakistani community in South Australia on social and cultural platforms and providing platform to the community members to not only celebrate their events of significant importance but also to showcase our ethnic diversity, unique culture and social and religious values to the multicultural communities in SA.`;

export const PassaConstitution = `Since then PAASA has been representing Pakistani community in South Australia on social and cultural platforms and providing platform to the community members to not only celebrate their events of significant importance but also to showcase our ethnic diversity, unique culture and social and religious values to the multicultural communities in SA.`;
////
export const bookVehicleTextParagraphOne = ` With the help of Department of Multicultural Affairs, Office of the Premier and Cabinet, under Stronger Together
grant program, PAASA purchased a 12-seater van for the Pakistani community in SA.`;
export const bookVehicleTextParagraphTwo = ` This vehicle is for hire for the community members whenever they need it subject to the availability of the
vehicle. Some of the purposes of this vehicle is to pick and drop newly arrived families from airport to their
place and to provide transport assistance to the new migrants, elderly community members, socially isolated
members, families and community groups.`;
///
export const assetHireTextParagraphOne = `PAASA provide this service with the help of Department of Multicultural Affairs, Office of the Premier and Cabinet, under Stronger Together grant program for the Pakistani community members in SA.`;
export const assetHireTextParagraphTwo = `Plan a BBQ party with your family and friends and hire all of the equipment you need from us. PAASA encourage the newly arrived families (new migrants) to organize get together with their community members for social networking. Arrange a BBQ party in your backyard and invite your multicultural friends’ families and neighbors to introduce them Pakistani BBQ food – Seekh Kabab, Chicken Tikka, Chicken Malai Boti, Reshmi Kabab and our rich hospitality.`;
export const assetHireTextParagraphThree = `If you want to hire projector or portable sound system, please speak to the management committee first.`;
///
export const urduSchoolTextParagraphOne = `Ethnic School Association of SA runs Language and Culture Schools in South Australia under the governance of Department of Education. Basic purpose of language school is to value the learning of community languages both educationally and socially to enhance and develop young people’s bilingual/multilingual literacies, intercultural capabilities and identities.`;
export const urduSchoolTextParagraphTwo = `PAASA used to run Urdu School for Pakistani community kids in Adelaide from 1990s till 2012. Structure of Urdu School includes school administration and academic committee to manage and run the school effectively under the constitution. Parents and other community members are part of these committees to run the school. School needs to run after hours for at least 2 hours a week.`;
export const urduSchoolTextParagraphThree = `Pakistan Australia Literary Forum (PALF) has recently revived and inaugurated Urdu school in Adelaide approved by the Community Language Schools of South Australia (CLSSA). Please contact urdu@palf.org.au for further details.`;
///
export const StateObj = [
  {
    para: "Provide a common platform whereby all community is provided with equal opportunity to participate and contribute to development in community planning, coordination and implementation; working cooperatively for all South Australian Pakistanis. To develop a sense of belonging among Pakistani members and strengthen community ties.",
  },
  {
    para: "To encourage and promote friendship, goodwill and better understanding between the Pakistani- Australians and Australians in general including awareness of Australian values within the Pakistani Community.",
  },
  {
    para: "Establish the basis for developing closer relationships across other Pakistani origin organisations.",
  },
  {
    para: "Augment PAASA's organisational capacity in order to provide individuals and families opportunities to connect within a respectful and friendly atmosphere and provide a better understanding across the community of the specific issues facing the community. Create resources to undertake these objectives.",
  },
  {
    para: "To observe and organise social gatherings on the occasions of Pakistan day in March and Independence day in August, besides Eid Milan parties and any other social/cultural activities decided by the Executive Committee, every year.",
  },
  {
    para: "To observe and organise social gatherings on the occasions of Pakistan day in March and Independence day in August, besides Eid Milan parties and any other social/cultural activities decided by the Executive Committee, every year.",
  },
  { para: "To encourage and organise sporting activities for its members." },
  {
    para: "To encourage organising literary and cultural activities for the members to have close contact with similar local and national organisations.",
  },
  {
    para: "To welcome and help new arrivals whether migrants, temporary residents or students in South Australia. Establish an effective framework for constructive communication and fulfilment of outcomes through appropriate forums to improve the quality of all South Australian Pakistani residents.",
  },
  {
    para: "To build a framework to improve skills and knowledge including well-being and resilience among Pakistani community members.",
  },
  {
    para: "To inculcate awareness on innovation and entrepreneurship within the Pakistani community and to promote business and commerce/trade between SA and Pakistan.",
  },
];
///
export const list = [
  {
    id: 1,
    title: "Provide a common platform whereby all community...",
    text: "Provide a common platform whereby all community is provided with equal opportunity to participate and contribute to development in community planning, coordination and implementation; working cooperatively for all South Australian Pakistanis. To develop a sense of belonging among Pakistani members and strengthen community ties.",
  },
  {
    id: 2,
    title: "To encourage and promote friendship, goodwill and...",
    text: "To encourage and promote friendship, goodwill and better understanding between the Pakistani- Australians and Australians in general including awareness of Australian values within the Pakistani Community.",
  },
  {
    id: 3,
    title: "Establish the basis for developing closer relationships...",
    text: "Establish the basis for developing closer relationships across other Pakistani origin organisations.",
  },
  {
    id: 4,
    title: `Augment PAASA's organisational capacity in order to...`,
    text: "Augment PAASA's organisational capacity in order to provide individuals and families opportunities to connect within a respectful and friendly atmosphere and provide a better understanding across the community of the specific issues facing the community. Create resources to undertake these objectives.",
  },
  {
    id: 5,
    title: "To observe and organise social gatherings...",
    text: "To observe and organise social gatherings on the occasions of Pakistan day in March and Independence day in August, besides Eid Milan parties and any other social/cultural activities decided by the Executive Committee, every year.",
  },

  {
    id: 7,
    title: "To encourage and organise sporting activities...",
    text: "To encourage and organise sporting activities for its members.",
  },

  {
    id: 9,
    title: `To welcome and help new arrivals whether migrants, temporary residents...`,
    text: `To welcome and help new arrivals whether migrants, temporary residents or students in South Australia. Establish an effective framework for constructive communication and fulfilment of outcomes through appropriate forums to improve the quality of all South Australian Pakistani residents.`,
  },
  {
    id: 10,
    title: "To build a framework to improve skills and knowledge...",
    text: "To build a framework to improve skills and knowledge including well-being and resilience among Pakistani community members.",
  },
  {
    id: 11,
    title: `To inculcate awareness on innovation and entrepreneurship...`,
    text: `To inculcate awareness on innovation and entrepreneurship within the Pakistani community and to promote business and commerce/trade between SA and Pakistan.`,
  },
];
///
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
/////
export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
////

export const appConfig = {
  stripeKey: "pk_test_vgTr3Bla3aMmTEcZ2xH02ZVx00e60sAlPE",
  // stripeKey: "pk_live_1H8T9Fphy9HlFFi8pNQiGe7S",
}