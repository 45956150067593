import React, { useMemo, useState } from "react";
import AppBar from "../../../commonComponents/AppBar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {  appConfig } from "../../../utils/Constants";

import BookingEventPaymentCard from "./BookingEventPaymentCard";

const stripePromise = loadStripe(appConfig.stripeKey);

const BookingEventPayment = ({
  BillingData,
  getVehicles,
  formData,
  setSteps,
  setOpen,
  freeTickets,
  fixedTickets,
  itemCounters,
}) => {
  console.log(BillingData, "billingData");
  return (
    <>
      <AppBar />
      <section>
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-12 col-sm-12 col-12">
              <div className="pay-contan">
                <div className="long-form signUp payment-form">
                  <div className="container-lg">
                    <div className="form-heading ">
                      <h4 className="SignUpAs">Payment</h4>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <Elements stripe={stripePromise}>
                          <BookingEventPaymentCard
                            BillingData={BillingData}
                            formData={formData}
                            getVehicles={getVehicles}
                            setSteps={setSteps}
                            setOpen={setOpen}
                            freeTickets={freeTickets}
                            fixedTickets={fixedTickets}
                            itemCounters={itemCounters}
                          />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingEventPayment;
