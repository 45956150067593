import React, { useState } from "react";
import AppBar from "../../commonComponents/AppBar";
import InputComponent from "../../commonComponents/Input/InputComponent";
import ButtonComponent from "../../commonComponents/Button/ButtonComponent";
import axios from "../../axiosInstance";
// import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ForgetPassword = () => {
  
  const [formData, setFormData] = useState({
    setPasswordUrl: `${window.location.origin}/SetPassword`,
  });

  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setFormData({ ...formData, ...obj });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    try {
      response = await axios.post("/user/forgotPassword", formData);
      if (response?.data?.type === "success") {
        // toast.success(response?.data?.message);
        Swal.fire("Done", "Password reset request processed, Please check your email inbox/spam to reset password.", "success");
      }
    } catch (e) {
      // if(e?.data)
      // toast.error(e?.response?.data?.message);
      Swal.fire("Request Failed", e?.response?.data?.message, "error");

      // console.log(e.response);
    }
  };
  return (
    <>
      <AppBar />
      <div className="form-contan ">
        <div className="long-form  logIn-form ">
          <div className="container-lg">
            <div className="form-heading ">
              <h4>Forget Password </h4>
            </div>

            <form type="submit" onSubmit={handleSubmit}>
              <InputComponent
                required={true}
                type="email"
                className="form-control email-icon"
                placeholder="Email"
                onChange={(e) => _setState("email", e?.target?.value)}
              />
              <ButtonComponent
                className="btn btn-green "
                text="Submit"
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
