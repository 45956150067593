import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import Slide3D from "./Slider3D";
import { Link } from "react-router-dom";

const Header = () => {
  const [sliderData, setSliderData] = useState();
  useEffect(() => {
    getSliders();
  }, []);
  const getSliders = async () => {
    let response;
    try {
      response = await axios.get("/slider/list");
      setSliderData(response?.data?.data);
    } catch (e) {
      console.trace(e);
    }
  };
  return (
    <>
      <div className="home-header">
        <div
          className="overlay-wapper "
          style={{ backgroundImage: "url(./header/bg-img.jpeg)" }}
        >
          {/* <img src="./header/bg-img.jpeg" className="header-bg-img" alt="" /> */}
          <div className="container-lg h-100 p-t50">
            <div
              style={{ alignItems: "baseline" }}
              className="row h-100 d-flex align-self-center header-main"
            >
              <div className="d-none d-sm-block col-lg-6 ">
                <div className="header-contant">
                  <span className="small-text">WELCOME TO PAASA</span>

                  <h1>
                    <div className="main-heading">
                      COMMUNITY CULTURE IN AUSTRALIA
                    </div>
                  </h1>
                  <p>
                    PAASA is a not-for-profit community organisation that
                    representing the Pakistani community in the multicultural
                    South Australian society.
                  </p>
                  <Link
                    to="/Meetthecommunity"
                    className="btn btn-green header-btn"
                  >
                    Meet with Pakistani community
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <Slide3D sliderData={sliderData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
