import React, { useState } from "react";
import "./Table.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditCategories from "../EditCategories/EditCategories";
import axios from "../../axiosInstance";
import { TramRounded } from "@mui/icons-material";
import Button from "@mui/material/Button";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5eb432",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomizedTable = ({ headings, tableData }) => {
  //   const [editCategory, setEditCategory] = useState(false);
  //   const [deleting, setDeleting] = useState(false);
  //   const getCats = async () => {
  //     const res = await axios.get("/category", {
  //       headers: {
  //         authorization: `Bearer ${JSON.parse(
  //           localStorage.getItem("adminToken")
  //         )}`,
  //       },
  //     });
  //     setCategories(res.data.data);
  //   };
  //   const handleDelete = async (id) => {
  //     setDeleting(id);
  //     let res = await axios({
  //       method: "delete",
  //       url: "/category/" + id,
  //       headers: {
  //         authorization: `Bearer ${JSON.parse(
  //           localStorage.getItem("adminToken")
  //         )}`,
  //       },
  //     });
  //     getCats();
  //     setDeleting(false);
  //   };
  //   const onChangeTable = (e) => {
  //     onChange(e.target.value);
  //   };
  // const sortArrayAsc = ()=>{
  //   sortArray();
  // }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headings?.map((heading) => (
              <StyledTableCell align="center">{heading}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center" className="first-name-c">
                {row?.eventTicket?.name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row?.eventTicket?.location}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row?.eventTicket?.startDate}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row?.eventTicket?.endDate}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row?.eventTicket?.customPrice.length > 0 ? (
                  <>
                    {row?.eventTicket?.customPrice?.map((item) => {
                      return (
                        <div>
                          {item?.title} - {item?.price}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  row?.eventTicket?.price
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Button
                  //   onClick={() => setEditCategory(row)}
                  variant="outlined"
                  color="success"
                >
                  <a href={row?.url}>Download</a>
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CustomizedTable;
