import React, { useState, useEffect } from "react";
import AppBar from "../../../commonComponents/AppBar";
import VehicleCards from "../../BookingVehicale/VehicleCards";

import { toast } from "react-toastify";

import Swal from "sweetalert2";

import axios from "../../../axiosInstance";

import { useLocation } from "react-router-dom";

import {
  bookVehicleTextParagraphOne,
  bookVehicleTextParagraphTwo,
} from "../../../utils/Constants";
import EventCards from "./EventCards";

const ReserveEventCard = () => {
  useEffect(() => {
    getVehicles();
    getUserInfo();
    getAdds();
  }, []);
  const [VehicleData, setVehicleData] = useState([]);
  const [vehicleFormData, setVehicleFormData] = useState();
  const [BillingData, setBillingData] = useState();
  const [formData, setFormData] = useState({});
  const [Open, setOpen] = useState(false);
  const [Steps, setSteps] = useState("0");
  const [adds, setadds] = useState();
  const location = useLocation();
  const getAdds = async () => {
    let response;
    try {
      response = await axios.get(`/advertisement${location.pathname}`);
      if (response?.data?.type === "success") {
        console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  const getVehicles = async () => {
    let response;
    try {
      response = await axios.get("/vehicle/");
      console.log(response, "lop");
      setVehicleData(response?.data?.data);
    } catch (e) {
      console.trace(e);
    }
  };
  const getUserInfo = async () => {
    let response;
    try {
      response = await axios.get("/user/me");
      if (response?.data?.type === "success") {
        console.log(response);
        setFormData((pre) => {
          return {
            ...pre,
            nameOfDriver:
              response?.data?.data?.profile?.firstName +
              " " +
              response?.data?.data?.profile?.lastName,
            firstName: response?.data?.data?.profile?.firstName,
            lastName: response?.data?.data?.profile?.lastName,
          };
        });
      }
    } catch (e) {
      console.trace(e);
    }
  };
  const calculateRent = async () => {
    console.log(formData);
    const obj = {
      startDate: formData?.startDate,
      endDate: formData?.endDate,
      vehicleId: formData?.vehicleId,
      nameOfDriver: formData?.nameOfDriver,
    };
    let response;
    try {
      response = await axios.post("/vehicleBooking/calculateRent", obj);
      setBillingData(response?.data?.data);
      console.log(response);
    } catch (e) {
      console.trace(e);
    }
  };
  const ProceedToPayment = async () => {
    const obj = {
      ...formData,
      amountCharged: BillingData?.totalPriceWithSecurity,
    };
    let response;
    try {
      response = await axios.post("/vehicleBooking/addBooking", obj);
      if (response?.data?.type === "success") {
        // toast.success("Data Saved Successfully");
        Swal.fire("Done", "Data Saved Successfully", "success");

        getVehicles();
        setSteps("");
        setOpen(false);
      }
      if (response?.data?.type === "error") {
        // toast.error(response?.data?.message);
        Swal.fire("Error", response?.data?.message, "error");
      }
    } catch (e) {
      console.trace(e);
      // toast.error(e?.response?.data?.message);
      Swal.fire("Error", e?.response?.data?.message, "error");
    }
  };
  return (
    <>
      <div className="container-lg">
        <EventCards
          VehicleData={VehicleData}
          formData={formData}
          getUserInfo={getUserInfo}
          getVehicles={getVehicles}
          setFormData={setFormData}
          calculateRent={calculateRent}
          BillingData={BillingData}
          ProceedToPayment={ProceedToPayment}
          setVehicleData={setVehicleData}
          Steps={Steps}
          setSteps={setSteps}
          Open={Open}
          setOpen={setOpen}
          vehicleFormData={vehicleFormData}
          setVehicleFormData={setVehicleFormData}
        />
      </div>
    </>
  );
};

export default ReserveEventCard;
