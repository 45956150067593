import React from "react";
import TextField from "@mui/material/TextField";
import { Modal } from "@mui/material";
import Button from "@mui/material/Button";
import CancelPresentationTwoToneIcon from "@mui/icons-material/CancelPresentationTwoTone";
import ClearIcon from "@mui/icons-material/Clear";

const VerificationModal = ({
  open,
  setOpen,
  verifyCode,
  loading,
  verificationCode,
  setverificationCode,
}) => {
  const SubmitCode = (verificationCode) => {
    verifyCode(verificationCode);
  };
  return (
    <>
      <Modal open={open}>
        <div
          style={{
            height: "45vh",
            width: "32vw",
            background: "#ffffff",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
          className="modal_container"
        >
          <div
            className="w-100 "
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "20px 5px",
            }}
          >
            <ClearIcon
              className="close-icon"
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          {!loading ? (
            <>
              <h3>Email Confirmation</h3>
              <div
                className="m-t10"
                style={{ width: "75%", textAlign: "center" }}
              >
                We have sent a verification code to your email, please enter it
                to complete you registration
              </div>
            </>
          ) : (
            <></>
          )}
          <div
            style={{
              margin: "8%",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              onChange={(e) => setverificationCode(e.target.value)}
              id="outlined-basic"
              label="Verification code"
              variant="outlined"
            />
            <div className="d-flex" style={{ width: "100%" }}>
              <Button
                onClick={() => SubmitCode(verificationCode)}
                style={{
                  width: "50%",
                  marginTop: "3%",
                  background: "#5eb432",
                  textAlign: "center",
                }}
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VerificationModal;
