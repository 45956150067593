import React, { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from "../../../axiosInstance";

import { toast } from "react-toastify";


import useResponsiveFontSize from "../../../commonComponents/useResponsiveFontSize";

import Swal from "sweetalert2";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const priceTypes = {
  Custom: "Custom",
  Free: "Free",
  Fixed: "Fixed",
};
const BookingEventPaymentCard = ({
  BillingData,
  getVehicles,
  formData,
  freeTickets,
  fixedTickets,
  itemCounters,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const navigate = useNavigate();
  const location = useLocation();
  const [counters, setCounters] = useState(itemCounters);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    try {
      let data = {
        amount: BillingData?.totalPriceWithSecurity,
      };
      const response = await axios.post("/payment/createPayment", data);
      if (response?.data?.type == "success") {
        const clientSecret = response?.data?.data?.clientSecret;
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: { card: elements.getElement(CardElement) },
        });
        const payload = {
          purchasedTicket: counters.reduce((a, b) => a + b, 0), // taking sum of all the elements
          customTickets: location.state.customTickets.map((customTicket, index) => {
            return {
              title: customTicket.title,
              purchasedTicket: counters[index],
            };
          }),
          ticketId: location.state.eventId,
        };
        const payloadForFree = {
          purchasedTicket: freeTickets,
          customTickets: [],
          ticketId: location.state.eventId,
        };

        const payloadForFixed = {
          purchasedTicket: fixedTickets,
          customTickets: [],
          ticketId: location.state.eventId,
        };
        if (paymentIntent?.status === "succeeded") {
          const obj = {
            ...formData,
            amountCharged: BillingData?.totalPriceWithSecurity,
          };
          let response;
          try {
            response = await axios.post(
              "/eventMangement/ticketBooking/bookTicket",
              location.state.priceType === priceTypes.Free
                ? {
                  ...payloadForFree,
                  paymentStatus: "SUCCESS",
                }
                : location.state.priceType === priceTypes.Fixed
                  ? { ...payloadForFixed, paymentStatus: "SUCCESS" }
                  : {
                    ...payload,
                    paymentStatus: "SUCCESS",
                  }
            );
            if (response?.data?.type === "success") {
              Swal.fire("Payment Completed", "Data Saved Successfully", "success");
              navigate("/userprofile", { state: { num: 2 } });
              return;
            }
            else if (response?.data?.type == "error") {
              Swal.fire("Payment Processed", "Data Saved Failed", "error");
            }
          } catch (e) {
            Swal.fire("Payment Processed", "Data Saved Failed", "error");
          }
        }
        else if (error?.message) {
          Swal.fire("Payment Processing Failed", error?.message, "error");
        } else {
          Swal.fire("Payment Processing Failed", paymentIntent?.cancellation_reason, "error");
        }
      }
    } catch (e) {
      Swal.fire("Payment Processing Failed", e?.response?.data?.message, "error");
    }
    return (
      <>
        <form onSubmit={handleSubmit}>
          <label>
            Amount To be Charged: {BillingData?.totalPriceWithSecurity}
          </label>
          <label className="w-100 parent-label">
            Card details
            <CardElement
              options={options}
              onReady={() => {
                console.log("CardElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardElement [blur]");
              }}
              onFocus={() => {
                console.log("CardElement [focus]");
              }}
            />
          </label>
          <button type="submit" disabled={!stripe}>
            Pay
          </button>
        </form>
      </>
    );
  };

}
export default BookingEventPaymentCard;
