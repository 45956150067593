import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import CartPaymentCard from './CartPaymentCard';
import {  appConfig } from "../../../utils/Constants";

const stripePromise = loadStripe(appConfig.stripeKey);


const CartPayment = ({ formData, setSteps, setFormData }) => {
  return (
    <>
      <section>
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-12 col-sm-12 col-12">
              <div className="pay-contan">
                <div className="long-form signUp payment-form">
                  <div className="container-lg">
                    <div className="form-heading ">
                      <h4 className="SignUpAs">Payment</h4>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <Elements stripe={stripePromise}>
                          <CartPaymentCard formData={formData} setSteps={setSteps} setFormData={setFormData} />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CartPayment;
