import moment from "moment";

export const cartArray = (array) => {
  localStorage.setItem('cartArray', JSON.stringify(array));
};

export const getCartArray = () => {
  let cartArray = JSON.parse(localStorage.getItem('cartArray') || '[]');
  return cartArray;
};

export const setDataInCartArray = (obj) => {
  localStorage.setItem('cartArray', JSON.stringify(obj));
};

export const RemoveDataInCartArray = () => {
  localStorage.removeItem('cartArray');
};

export const getPlanActiveEndDate = ()=>{
  const currentDate = moment();
  const currentYear = currentDate.year();
  const june30CurrentYear = moment([currentYear, 5, 30]);
  let june30Result

  if (currentDate.isAfter(june30CurrentYear)) {
    // 30th June of current year has already passed, get date for next year's 30th June
    june30Result = june30CurrentYear.add(1, 'year');
  } else {
    // 30th June of current year hasn't passed yet, get date for this year's 30th June
    june30Result = june30CurrentYear;
  }
  const endDate = june30Result.format('DD-MM-YYYY');
  return endDate
};

export const getPlanActiveEndDateYear = ()=>{
  const currentDate = moment();
  const currentYear = currentDate.year();
  const june30CurrentYear = moment([currentYear, 5, 30]);
  let june30Result

  if (currentDate.isAfter(june30CurrentYear)) {
    // 30th June of current year has already passed, get date for next year's 30th June
    june30Result = june30CurrentYear.add(1, 'year');
  } else {
    // 30th June of current year hasn't passed yet, get date for this year's 30th June
    june30Result = june30CurrentYear;
  }
  const endDate = june30Result.format('YYYY');
  return endDate
};