import React, { useState, useEffect } from "react";
import AccountDetails from "./AccountDetails";
import ChangePassword from "../ChangePassword/ChangePassword";
import BookingRequest from "../UserProfileList/BookingRequest";
import axios from "../../../axiosInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Root = ({
  activeSideMenu,
  addressLine1,
  setState,
  passwordData,
  setPasswordData,
}) => {
  useEffect(() => {
    getUserInfo();
  }, []);

  const [UserData, setUserData] = useState();
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setUserData({ ...UserData, ...obj });
  };
  const getUserInfo = async () => {
    let response;
    try {
      response = await axios.get("/user/me");
      if (response?.data?.type === "success") {
        console.log(response?.data?.data, "response?.data?.data");
        setUserData(response?.data?.data);
        //await getBookingRequest(response?.data?.data.id);
        //setloading(false);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  const handleSubmitPassword = async () => {
    let response;
    try {
      response = await axios.post("/user/changePassword", passwordData);
      console.log(response);
      if (response?.data?.type == "success") {
        Swal.fire(
          "Password Changed!",
          "Your password has been changed successfully",
          "success"
        );
        setPasswordData("");
      }
    } catch (e) {
      // if(e?.data)
      // toast.error(e?.response?.data?.message);
      Swal.fire("Error", e?.response?.data?.message, "error");
      console.log(e.response);
    }
    return;
  };

  return (
    <>
      {activeSideMenu == 1 && UserData ? (
        <AccountDetails
          UserData={UserData}
          setUserData={setUserData}
          setState={_setState}
          addressLine1={addressLine1}
        />
      ) : (
        <></>
      )}
      {activeSideMenu == 2 ? <BookingRequest UserData={UserData} /> : <></>}
      {activeSideMenu == 3 ? (
        <ChangePassword
          passwordData={passwordData}
          setPasswordData={setPasswordData}
          handleSubmitPassword={handleSubmitPassword}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Root;
