import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AppBar from "../../commonComponents/AppBar";
import { Link } from "react-router-dom";

const SportsPrograms = ({ home }) => {
  return (
    <>
      <section>
        {!home ? <AppBar /> : <></>}
        <div className="container-lg m-tb40">
          <div className="row">
            <div className="d-none d-md-block col-lg-6">
              <div
                className="sport-bg-img"
                
              >
                <img className="topLeft-img" src="./home/sports1.png" alt="" />
                <img className="bottomRight-img" src="./home/sports2.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 main-program">
              <div className="comunity_margin_top wt-bnr-inr m-t50">
                <div className="home-title-name p-tb10">
                  <h1 className="wt-title">Community Sports Programs</h1>
                </div>
                <div class="sport-bg-img d-sm-block d-md-none">
                  <img src="./home/sports1.png" class="topLeft-img img-fluid" alt="Responsive image" />
                  <img src="./home/sports2.png" class="bottomRight-img img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div className="home-para">
                <p class="text-justify">
                  This was Dr Ashfaque Ahmed who came to Australia in 1962 in
                  Sydney and then moved to Adelaide to work in The Royal
                  Adelaide hospital in 1965, decided to start a Pakistani
                  Association. His idea finally came into existence in 1967 with
                  the name as Australian Pakistani Association. Dr. Ashfaque
                  Ahmed was the first President with Vice President Captain
                  Sabir Shah of the Pakistani Army, and treasurer Mr. Azhar
                  Jamal Minhas from Lahore. Later in 1984 that association
                  become Pakistani Australian Association of SA- PAASA with Mr.
                  Shamim Noori as President
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SportsPrograms;
