import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AppBar from '../../commonComponents/AppBar';
import { list } from '../../utils/Constants';

const StrategicObjective = ({ home }) => {
  const [CurrIndex, setCurrIndex] = useState('0');
  return (
    <>
      <section>
        {!home ? <AppBar /> : <></>}
        <div className="container-lg">
          <div className="wt-bnr-inr m-t150 sm-m-t5">
            <div className="home-title-name p-tb10">
              <h1 className="wt-title"> Strategic Objectives</h1>
            </div>
          </div>
          <img src="./home/StrategicObjectives.avif" class=" d-sm-block d-md-none img-fluid img-thumbnail" alt="Responsive image" />
          <div className="row">
            <div className="col-lg-7">
              <div className="strt-list m-tb20">
                {list.map((value, index) => {
                  return (
                    <div
                      className={value.id === CurrIndex ? 'list-body color-body' : ' list-body '}
                      key={index}
                      onClick={() => {
                        if (CurrIndex === value.id) return setCurrIndex('');

                        setCurrIndex(value.id);
                      }}
                    >
                      <div className="list-heading">
                        {value.id === CurrIndex ? (
                          <>
                            <p className="list-text">{value.text} </p>
                          </>
                        ) : (
                          <>
                            <p className="list-title">{value.title}</p>{' '}
                          </>
                        )}
                        <KeyboardArrowDownRoundedIcon />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="side-img">
                <img src="./home/StrategicObjectives.avif" class="d-none d-md-block" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StrategicObjective;
