import React, { useEffect, useState } from 'react';
import AppBar from '../../commonComponents/AppBar';
import axios from '../../axiosInstance';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { urduSchoolTextParagraphOne, urduSchoolTextParagraphTwo, urduSchoolTextParagraphThree } from '../../utils/Constants';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UrduSchool = ({ home, addshow }) => {
  const [CertificationData, setCertificationData] = useState();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCertification();
  }, []);
  const getCertification = async () => {
    const res = await axios.get('/certificationworkshops/list', {
      headers: {
        authorization: `Bearer ${JSON.parse(localStorage.getItem('adminToken'))}`,
      },
    });
    setCertificationData(res.data.data);
  };
  console.log(CertificationData, 'CertificationData');
  return (
    <>
      <section className="p-t120 urduschool-p-0">
        {!home ? <AppBar /> : <></>}
        <div className="container-lg">
          <div className="wt-bnr-inr">
            <div className="home-title-name p-tb10">
              <h1 className="wt-title">Urdu School</h1>
            </div>
              <div className="side-img ">
                <img className="rounded-img d-sm-block d-md-none " src="./home/urduschool.png" alt="" />
              </div>

          </div>{' '}
          <div className="row">
            <div className="col-xl-7">
              <div className="home-para">
                <p className="text-align-justify">{urduSchoolTextParagraphOne}</p>
                <p className="text-align-justify">{urduSchoolTextParagraphTwo}</p>
                <p className="text-align-justify">{urduSchoolTextParagraphThree}</p>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="side-img">
                <img className="rounded-img d-none d-md-block" src="./home/urduschool.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ width: '90%', display: 'flex', flexWrap: 'wrap', margin: 'auto' }}>
          {CertificationData?.map((val) => (
            <>
              <div class="courses-container-urdu-school">
                <div class="course">
                  <div class="course-preview-urdu-school">
                    <img src={val?.picture} />
                  </div>
                  <div class="course-info">
                    <h6>{val?.type}</h6>
                    <h6>{val?.description}</h6>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div> */}
        {!addshow ? (
          <Box sx={{ width: '85%', margin: '3% auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Certifications" {...a11yProps(0)} />
                <Tab label="Workshops" {...a11yProps(1)} />
                <Tab label="Training" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <div className="wt-bnr-inr  p-t25">
              <div className="container-lg">
                <div className="banner-title-name m-t40">
                  <h1 className="wt-title">{value == 0 ? 'Certification' : value == 1 ? 'Workshops' : 'Training'}</h1>
                </div>
              </div>
            </div>

            <TabPanel style={{ display: 'flex' }} value={value} index={0}>
              <div class="courses-container-urdu-school">
                {CertificationData?.filter((_) => _?.type == 'Certification').map((val) => (
                  <div class="course-urdu-school">
                    <div class="course-preview-urdu-school">
                      <img src={val?.picture} />
                    </div>
                    <div class="course-info">
                      <h6>{val?.description}</h6>
                      <h6 className="date-certification">{val?.date}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div class="courses-container-urdu-school">
                {CertificationData?.filter((_) => _?.type == 'Workshop').map((val) => (
                  <div class="course-urdu-school">
                    <div class="course-preview-urdu-school">
                      <img src={val?.picture} />
                    </div>
                    <div class="course-info">
                      <h6>{val?.description}</h6>
                      <h6 className="date-certification">{val?.date}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div class="courses-container-urdu-school">
                {CertificationData?.filter((_) => _?.type == 'Training').map((val) => (
                  <div class="course-urdu-school">
                    <div class="course-preview-urdu-school">
                      <img src={val?.picture} />
                    </div>
                    <div class="course-info">
                      <h6>{val?.description}</h6>
                      <h6 className="date-certification">{val?.date}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
          </Box>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default UrduSchool;
