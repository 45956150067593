import React, { useState, useEffect } from "react";
import AppBar from "../../commonComponents/AppBar";
import { useLocation } from "react-router-dom";
import axios from "../../axiosInstance";
const CovidInfo = () => {
  const [adds, setadds] = useState();
  useEffect(() => {
    getadds();
    document.title = "COVID 19 info";
  }, []);
  const location = useLocation();
  const getadds = async () => {
    let response;
    try {
      response = await axios.get(`/advertisement${location.pathname}`);
      if (response?.data?.type === "success") {
        console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  return (
    <>
      <AppBar />
      <section className="p-t90 ">
        <div className="wt-bnr-inr m-b20  p-t100 covid-info-1">
          <div className="container-lg">
            <div className="row">
              <div className="col-xl-9 col-md-7 col-sm-12 col-lg-8">
                <div className="banner-title-name ">
                  <h1 className="wt-title">COVID 19 Vaccinations info</h1>
                </div>
                <div className="col-xl-3 col-sm-12 col-md-5 col-lg-4 d-sm-block d-md-none img-fluid img-thumbnai">
                  <img
                    // style={{ width: "80%", marginLeft: "25%" }}
                    src="./home/covid1.png"
                    alt=""
                  />
                </div>
                <div className="para">
                  <p class="text-justify">
                    Coronavirus disease (COVID-19) is an infectious disease
                    caused by the SARS-CoV-2 virus. Most people infected with
                    the virus will experience mild to moderate respiratory
                    illness and recover without requiring special treatment.
                    However, some will become seriously ill and require medical
                    attention. Older people and those with underlying medical
                    conditions like cardiovascular disease, diabetes, chronic
                    respiratory disease, or cancer are more likely to develop
                    serious illness.
                  </p>
                </div>
                {/* 
            <div className="banner-mid-image m-tb20">
              <img className="rounded" src="./Covid19Info/covid-header.avif" alt=" Covid19Info" />
            </div> */}
              </div>
              <div className="col-xl-3 col-sm-12 col-md-5 col-lg-4 d-none d-md-block">
                <img
                  // style={{ width: "80%", marginLeft: "25%" }}
                  src="./home/covid1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-contant m-t50">
          <div className="container-lg">
            <div className="row">
              <div className="col-xl-12">
                <div className="para">
                  <p>
                    The best way to prevent and slow down transmission is to be
                    well informed about the disease and how the virus spreads.
                    Protect yourself and others from infection by staying at
                    least 1 metre apart from others, wearing a properly fitted
                    mask, and washing your hands or using an alcohol-based rub
                    frequently. Get vaccinated when it’s your turn and follow
                    local guidance. The virus can spread from an infected
                    person’s mouth or nose in small liquid particles when they
                    cough, sneeze, speak, sing or breathe. These particles range
                    from larger respiratory droplets to smaller aerosols. It is
                    important to practice respiratory etiquette, for example by
                    coughing into a flexed elbow, and to stay home and
                    self-isolate until you recover if you feel unwell.
                  </p>
                  <p>
                    To prevent infection and to slow transmission of COVID-19,
                    do the following: Get vaccinated when a vaccine is available
                    to you. Stay at least 1 metre apart from others, even if
                    they don’t appear to be sick. Wear a properly fitted mask
                    when physical distancing is not possible or when in poorly
                    ventilated settings. Choose open, well-ventilated spaces
                    over closed ones. Open a window if indoors. Wash your hands
                    regularly with soap and water or clean them with
                    alcohol-based hand rub. Cover your mouth and nose when
                    coughing or sneezing. If you feel unwell, stay home and
                    self-isolate until you recover.
                  </p>
                </div>
              </div>
              {/* <div className="col-xl-5">
                <div className="side-img">
                  <img src="./about/about-img2.avif" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="d-flex adds-img-styl">
          <img src={adds?.picture ?? "../../../home/addsdummy.png"} />
        </div>
      </section>
    </>
  );
};

export default CovidInfo;
