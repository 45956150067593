import React, { useState, useEffect } from "react";
import AppBar from "../../commonComponents/AppBar";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Store } from "../../StoreContext";
import Label from "../../commonComponents/Label/Label";
import { getPlanActiveEndDate } from "../../utils/Helpers";
import axios from "../../axiosInstance";

import * as FormValidations from "../../utils/Validations";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import InputComponent from "../../commonComponents/Input/InputComponent";
import { profession } from "../../utils/Constants";
import moment from "moment";

// let i;
const MemberDetail = () => {
  const [childrenAges, setchildrenAges] = useState();
  const [formData, setFormData] = useState({
    numberOfChildren: 0,
    childernAges: [],
  });
  const [adds, setadds] = useState();
  useEffect(() => {
    getadds();
  }, []);
  const navigate = useNavigate();
  let location = useLocation();
  const store = Store();
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setFormData({ ...formData, ...obj });
  };
  // const _setStateChildren = (key, value) => {
  //   const obj = {
  //     age: value,
  //   };
  //   setFormData((pre) => {
  //     return {
  //       ...pre,
  //       childernAges: [...formData?.childernAges, obj],
  //     };
  //   });
  // };
  const getadds = async () => {
    let response;
    try {
      response = await axios.get(`/advertisement${location.pathname}`);
      if (response?.data?.type === "success") {
        // console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  const handleChange = (e, id) => {
    // debugger;
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (e.target.value.includes("-")) {
      document.getElementById(id).value = "";
    } else {
      setchildrenAges((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  };
  // console.log(childrenAges, "dgsd");
  function onlyNumberKey(evt) {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }
  return (
    <>
      <AppBar />
      <div className="form-contan">
        <div className="long-form  logIn-form signUp">
          <div className="container-lg">
            <div className="form-heading ">
              <h4>Create your account</h4>
            </div>
            <form>
              <div className="row">
                <div
                  style={{ justifyContent: "flex-start" }}
                  className="col-lg-12 d-flex"
                >
                  <div className="couple">
                    <span className="c-title">
                      {location?.state?.packageName}
                    </span>
                    <span className="couple-btn">
                      {location?.state?.packagePrice}{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div className="col-lg-6">
                  <Label text="Profession" />
                  <select
                    required
                    onChange={(e) =>
                      _setState("studyingCourse", e?.target?.value)
                    }
                    class="form-control form-select"
                  >
                    <option value="">What is your field of profession</option>
                    {profession?.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>

                  {location?.state?.packageName == "FAMILY WITH CHILDREN" ||
                    location?.state?.packageName == "NEW ARRIVALS" ? (
                    <>
                      <Label text="Kids under 10 years" />
                      <select
                        required
                        onChange={(e) => {
                          _setState(
                            "numberOfChildren",
                            parseInt(e.target.value)
                          );
                          const objlength = Object.keys(childrenAges)?.length;
                          const value = e.target.value;
                          console.log(objlength, value);
                          console.log(Object.keys(childrenAges)?.length);
                          if (Object.keys(childrenAges)?.length) {
                            if (
                              parseInt(e.target.value) <
                              Object.keys(childrenAges)?.length
                            ) {
                              let length =
                                Object.keys(childrenAges)?.length -
                                parseInt(e.target.value);
                              let indexesArray = Object.keys(childrenAges);
                              console.log(length, "length");
                              console.log(indexesArray, "indexesArray");
                              console.log(
                                indexesArray.length - 1,
                                "indexesArray.length - 1"
                              );
                              for (
                                let index = indexesArray.length - 1;
                                index >= indexesArray.length - length;
                                index--
                              ) {
                                const element = indexesArray[index];
                                delete childrenAges[element];
                              }
                            }
                          }
                        }}
                        class="form-control form-select"
                      >
                        <option value="">
                          No of kids you have under 10 years of age
                        </option>
                        <option value="0">Under 1 Year</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                    </>
                  ) : (
                    <></>
                  )}
                  {location?.state?.packageName == "NEW ARRIVALS" ? (
                    <>
                      <Label text="Date of arrival" />
                      <div className="col-lg-12">
                        <InputComponent
                          required={true}
                          type="date"
                          className="form-control"
                          id="date"
                          placeholder="date"
                          onChange={(e) =>
                            _setState("dateofArrival", e.target.value)
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="goBack">
                    <i className="	fa fa-angle-left"></i>
                    <Link to="/MembershipPlan"> BACK </Link>
                  </div>
                </div>

                <div className="col-lg-6">
                  {location?.state?.packageName == "SINGLE AND STUDENT" ? (
                    <>
                      <Label text="Course" />
                      <InputComponent
                        required={true}
                        type="text"
                        className="form-control"
                        id="studying"
                        placeholder="What are you studying"
                        onChange={(e) => _setState("studying", e.target.value)}
                      />
                      <Label text="College name" />
                      <InputComponent
                        required={true}
                        type="email"
                        className="form-control"
                        id="spouseEmailAddress"
                        placeholder="Uni/College Name"
                        onChange={(e) =>
                          _setState("collegename", e.target.value)
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {location?.state?.packageName != "SINGLE AND STUDENT" ? (
                    <>
                      <Label text="Spouse Name" />
                      <InputComponent
                        required={true}
                        type="text"
                        className="form-control"
                        id="spouseName"
                        placeholder="Spouse name"
                        onChange={(e) =>
                          _setState("spouseName", e.target.value)
                        }
                      />

                      <Label text="Spouse Email" />
                      <InputComponent
                        required={true}
                        type="email"
                        className="form-control"
                        id="spouseEmailAddress"
                        placeholder="Spouse Email"
                        onChange={(e) =>
                          _setState("spouseEmailAddress", e.target.value)
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {location?.state?.packageName == "FAMILY WITH CHILDREN" ||
                    location?.state?.packageName == "NEW ARRIVALS" ? (
                    [
                      ...Array(
                        formData?.numberOfChildren
                          ? formData?.numberOfChildren
                          : 0
                      ),
                    ].map((elementInArray, index) => (
                      <>
                        <Label text="Age" />
                        <input
                          maxLength={3}
                          type="text"
                          required={true}
                          className="form-control"
                          id={`child+${index + 1}`}
                          name={`child+${index + 1}`}
                          placeholder="Enter age"
                          onChange={(e) =>
                            handleChange(e, `child+${index + 1}`)
                          }
                          min="1"
                          max="100"
                        // onkeydown="return false //return value false"
                        />
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                  <button
                    style={{ marginTop: "5%" }}
                    onClick={async (e) => {
                      e.preventDefault();
                      let isValidated;
                      if (
                        location?.state?.packageName == "SINGLE AND STUDENT"
                      ) {
                        isValidated = FormValidations.SignUpSingleAndStudent({
                          formData,
                        });
                      }
                      if (
                        location?.state?.packageName != "SINGLE AND STUDENT"
                      ) {
                        isValidated = FormValidations.SignUp({
                          formData,
                        });
                      }

                      if (!isValidated) {
                        return;
                      }
                      if (
                        location?.state?.packageName == "SINGLE AND STUDENT"
                      ) {
                        navigate("/Payment", {
                          state: {
                            collegename: formData?.collegename,
                            studyingCourse: formData?.studyingCourse,
                            studying: formData?.studying,
                            datdateofArrivale: formData?.dateofArrival,
                            packageName: location?.state?.packageName,
                            packagePrice: location?.state?.packagePrice,
                            planId: location?.state?.planId
                            // signupToken: location?.state?.signupToken,
                          },
                        });
                      }
                      if (
                        location?.state?.packageName != "NEW ARRIVALS" &&
                        location?.state?.packageName != "SINGLE AND STUDENT"
                      ) {
                        navigate("/Payment", {
                          state: {
                            numberOfChildren: formData?.numberOfChildren,
                            spouseEmailAddress: formData?.spouseEmailAddress,
                            spouseName: formData?.spouseName,
                            studyingCourse: formData?.studyingCourse,
                            datdateofArrivale: formData?.dateofArrival,
                            packageName: location?.state?.packageName,
                            packagePrice: location?.state?.packagePrice,
                            planId: location?.state?.planId,
                            // signupToken: location?.state?.signupToken,
                            childernAges: childrenAges
                              ? Object.values(childrenAges)
                              : "",
                          },
                        });
                      }
                      if (location?.state?.packageName == "NEW ARRIVALS") {
                        let data = {
                          spouseName: formData?.spouseName,
                          spouseEmailAddress: formData?.spouseEmailAddress,
                          numberOfChildren: parseInt(
                            formData?.numberOfChildren
                          ),
                          studyingCourse: formData?.studyingCourse,
                          dateofArrival: formData?.dateofArrival,
                          childernAges: childrenAges
                            ? Object.values(childrenAges)
                            : "",
                        };
                        try {

                          await axios({
                            method: "post",
                            url: "/plans/userPlanActivation",
                            data: {
                              planId: location?.state?.planId,
                              startDate: moment().format("DD-MM-YYYY"),
                              endDate: getPlanActiveEndDate()
                            }
                          });
                        } catch (error) {
                          Swal.fire("Plan Activation Failed", error?.response?.data?.message, "error");
                        }
                        try {
                          let response = await axios({
                            method: "patch",
                            url: "/user/updateProfile",
                            // headers: {
                            //   authorization: `Bearer ${location?.state?.signupToken}`,
                            // },
                            data,
                          });
                          if (response?.data?.type == "success") {
                            // toast.success(response?.data?.message);
                            Swal.fire(
                              "Plan Selected",
                              response?.data?.message,
                              "success"
                            );
                            setTimeout(() => {
                              navigate("/AboutPaasa");
                            }, 1000);
                          }
                        } catch (e) {
                          // toast.error(e.response.data.message);
                          Swal.fire(
                            "Error",
                            e?.response?.data?.message,
                            "error"
                          );
                        }
                      }
                    }}
                    className="btn btn-green "
                  >
                    {location?.state?.packageName == "NEW ARRIVALS"
                      ? "Submit"
                      : "Proceed to payment"}
                  </button>
                  <div className="member">
                    <Link to="/LogIn"> SIGN IN </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="d-flex adds-img w-100">
        <img
          style={{ height: "200px", width: "50%", marginTop: "2%" }}
          src={adds?.picture ?? "../../../home/addsdummy.png"}
        />
      </div>
    </>
  );
};

export default MemberDetail;
