import React from "react";
import AdsBanner from "./AdsBanner";
import CovidVaccInfo from "./CovidVaccInfo";
import Header from "./Header";
// import HomeNav from "./HomeNav";
import AppBar from "../../commonComponents/AppBar";
import NewEvents from "./NewEvents";
import SportsPrograms from "./SportsPrograms";
import StrategicObjective from "./StrategicObjective";
import UrduSchool from "./UrduSchool";
import WelcomToPaasa from "./WelcomToPaasa";

const Home = () => {
  return (
    <>
      <AppBar />
      <Header />
      <WelcomToPaasa />
      <CovidVaccInfo home={true} />
      <AdsBanner />
      <UrduSchool home={true} addshow={true} />
      <NewEvents home={true} addshow={true} />
      <SportsPrograms home={true} />
      <StrategicObjective home={true} />
    </>
  );
};

export default Home;
