import React, { useMemo, useState } from 'react';
import AppBar from '../../commonComponents/AppBar';
import { loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import {  appConfig } from "../../utils/Constants";

import BookingVehicalePaymentCard from './BookingVehicalePaymentCard';

const stripePromise = loadStripe(appConfig.stripeKey);

const BookingVehicalePayment = ({ BillingData, getVehicles, formData, setSteps, setOpen }) => {
  return (
    <>
      <AppBar />
      <section>
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-12 col-sm-12 col-12">
              <div className="pay-contan">
                <div className="long-form signUp payment-form">
                  <div className="container-lg">
                    <div className="form-heading ">
                      <h4 className="SignUpAs">Payment</h4>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <Elements stripe={stripePromise}>
                          <BookingVehicalePaymentCard
                            BillingData={BillingData}
                            formData={formData}
                            getVehicles={getVehicles}
                            setSteps={setSteps}
                            setOpen={setOpen}
                          />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingVehicalePayment;
