import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "../../axiosInstance";

import AppBar from "../../commonComponents/AppBar";
import InputComponent from "../../commonComponents/Input/InputComponent";
import * as FormValidations from "../../utils/Validations";

import Button from "@mui/material/Button";

const ContactUs = () => {
  const [UserData, setUserData] = useState();
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setUserData({ ...UserData, ...obj });
  };
  const handleSubmit = async () => {
    const isValidated = FormValidations.ContactUs({
      UserData,
    });

    if (!isValidated) {
      return;
    }

    let response;
    try {
      response = await axios.post("/user/contactus", UserData);
      if (response?.data?.type == "success") {
        // toast.success("Data Saved Succesfully");
        Swal.fire("Done", "Data Saved Successfully", "success");
      }
    } catch (e) {
      // if(e?.data)
      // toast.error(e?.response?.data?.message);
      Swal.fire("Error", e?.response?.data?.message, "error");
    }
  };
  return (
    <>
      <AppBar />
      <section className="p-t90 ">
        {/* <div style={{ border: "1px solid" }} className="col-xl-12">
          <img src="https://cdn.hswstatic.com/gif/maps.jpg" />
        </div> */}
        <img className="rounded" src="./home/contact.png" alt="" />
        <div className="wt-bnr-inr m-b20  p-t50">
          <div className="container-lg">
            <div className="row">
              <div className="col-xl-6">
                <div className="banner-title-name m-t40">
                  <h1 className="wt-title">Get In Touch</h1>
                </div>
                <div className="para">
                  <p>
                    We would love to hear from you! Whether you have questions,
                    suggestions, or want to get involved with the Pakistani
                    Australian Association of South Australia (PAASA), feel free
                    to reach out. Our team is here to assist you and provide any
                    information you need.
                  </p>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="banner-title-name m-t40">
                  <h1 className="wt-title">Talk To Us Anytime</h1>
                </div>
                <Box className="input_container pt-2">
                  <Box className="flex-col w-100">
                    <label className="pb-2">Name </label>
                    <InputComponent
                      onChange={(e) => _setState("name", e.target.value)}
                      //value={UserData?.phoneNumber}
                      placeholder="Name"
                      className="accunt_details_input form-control"
                    />
                  </Box>
                  <Box className="flex-col w-100">
                    <label className="pb-2">Email </label>
                    <InputComponent
                      onChange={(e) => _setState("email", e.target.value)}
                      //value={UserData?.phoneNumber}
                      placeholder="Email"
                      className="accunt_details_input form-control"
                    />
                  </Box>
                </Box>
                <Box className="input_container">
                  <Box className="flex-col w-100">
                    <label className="pb-2">Subject </label>
                    <InputComponent
                      onChange={(e) => _setState("subject", e.target.value)}
                      //value={UserData?.phoneNumber}
                      placeholder="Subject"
                      className="accunt_details_input form-control"
                    />
                  </Box>
                </Box>
                <Box className="input_container">
                  <Box className="flex-col w-100">
                    <label className="pb-2">Message </label>
                    <textarea
                      rows="7"
                      onChange={(e) => _setState("message", e.target.value)}
                      placeholder="Message"
                      className="accunt_details_input textarea-contact "
                    ></textarea>
                  </Box>
                </Box>
                <div class="text-center w-100 mt-4">
                  <Button
                    onClick={handleSubmit}
                    sx={{ marginLeft: "14rem", background: "#5eb432" }}
                    variant="contained"
                  >
                    Send Message
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
