import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AppBar from "../../commonComponents/AppBar";
import axios from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";

const NewEvents = ({ home, addshow }) => {
  const location = useLocation();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(
    home ? {} : location.state?.event
  );
  const ref = useRef(null);

  const navigate = useNavigate();
  console.log({ selectedEvent });
  useEffect(() => {
    getEvents();
    document.title = "Events";
  }, []);
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const getEvents = async () => {
    let response;
    try {
      response = await axios.get(`/eventMangement/event/list`);
      if (response?.data?.type === "success") {
        console.log(response?.data?.data, "events");
        setEvents(response?.data?.data);
        if (!location.state?.event) setSelectedEvent(response?.data?.data[0]);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow arrow-next" onClick={onClick}>
        <ArrowForwardIosIcon />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow arrow-prev" onClick={onClick}>
        <ArrowBackIosNewIcon />
      </div>
    );
  };
  const handleReserveClickHandler = () => {
    navigate("/ReserveEvent", {
      state: {
        customTickets: selectedEvent?.eventTicket?.customPrice,
        eventId: selectedEvent.eventTicket.id,
        priceType: selectedEvent?.eventTicket.priceType,
        price: selectedEvent?.eventTicket.price,
        ticketType: selectedEvent?.eventTicket.ticketType,
        fixedTickets: selectedEvent?.eventTicket.fixedTickets,
      },
    });
  };
  const detectPrice = () => {
    var price = selectedEvent?.eventTicket.price;
    var customPrice = selectedEvent?.eventTicket.customPrice;
    if (price === 0) {
      if (customPrice.length) {
        return customPrice.map((p) => {
          return (
            <strong>
              {p.title + ": $" + p.price}
              <br />
            </strong>
          );
        });
      }
      return "Free";
    }
    return (
      "$" +
      price * selectedEvent?.eventTicket?.fixedTickets +
      "/Per " +
      (selectedEvent?.eventTicket?.ticketType === "Single"
        ? "Person"
        : selectedEvent?.eventTicket?.fixedTickets + " Persons")
    );
  };
  const handleEventClickHome = (event) => {
    navigate("/NewsAndEvents", { state: { event: event } });
  };
  const settings = {
    infinity: true,
    lazyload: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <>
      <section>
        {!home ? (
          <>
            <AppBar />
            {selectedEvent ? (
              <section ref={ref} className="p-t70">
                <div className="wt-bnr-inr  m-b40 p-t100">
                  <div className="container-lg">
                    <div className="banner-title-name p-tb20">
                      <h1 className="wt-title">{selectedEvent?.name}</h1>
                    </div>

                    <div className="banner-image">
                      <img
                        className="rounded"
                        src={selectedEvent?.image}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="page-contant">
                  <div className="container-lg row-cols-2 input-group">
                    <div className="para">
                      <p className="text-align-justify ">
                        {selectedEvent?.termsCondition}
                      </p>
                    </div>
                    <div className="text-center">
                      <div className="para">
                        <strong
                          className="text-align-justify "
                          style={{ color: "#5eb432" }}
                        >
                          {detectPrice()}
                        </strong>
                      </div>
                      <button
                        type="submit"
                        style={{ width: "46.5%" }}
                        className="btn btn-green"
                        onClick={handleReserveClickHandler}
                      >
                        Reserve now
                      </button>
                      <div className="para">
                        <p>{selectedEvent?.remainsTickets} Tickets Left</p>
                      </div>
                    </div>
                    <div className="para">
                      <strong
                        className="text-align-justify "
                        style={{ color: "#5eb432" }}
                      >
                        {moment(selectedEvent?.startDate, "DD-MM-YYYY").format(
                          "MMMM Do, YYYY"
                        )}{" "}
                        @{" "}
                        {moment(selectedEvent?.startTime, "HH:mm:ss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(selectedEvent?.endTime, "HH:mm:ss").format(
                          "hh:mm A"
                        )}
                      </strong>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              "Please Select an Event"
            )}
          </>
        ) : (
          <></>
        )}

        <div className="container-lg">
          <div className="wt-bnr-inr m-t150 sm-m-t5">
            <div className="home-title-name p-tb10 left-green">
              <h1 className="wt-title">Upcoming Events</h1>
            </div>
          </div>
          <div class="home-para">
            <p class="text-justify">
              <div class="para">
                <div class="about-StatObj">
                  {/*  <div class="list">
                  <div class="dot"></div>
                  <p>
                  Meet &amp; Greet for new migrants (Planned in end 2022).
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Australian Day 26th Jan 2023.
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Harmony and Pakistan Day (Planned in March 2023).
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Anzac Day 25th Apr 2023.
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Eid al Fitr Function (Planned in May 2023).
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Eid al Adha Gala Dinner.
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Independence Day Celebrations (Planned in Aug 2023).
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  AGM (Planned in Sep 2023).
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Various Sports events.
                  </p>
                </div>
                <div class="list">
                  <div class="dot"></div>
                  <p>
                  Various Trainings and awareness sessions.
                  </p>
                </div> */}
                </div>
              </div>
            </p>
          </div>

          <div className=" card-slider-bg">
            <div className=" d-sm-block blue-cut"></div>
            <div className="card-slider ">
              <div>
                <Slider {...settings}>
                  {events.map((val, index) => {
                    return (
                      <>
                        <div
                          class="card"
                          key={val.id}
                          onClick={() =>
                            home
                              ? handleEventClickHome(val)
                              : handleEventClick(val)
                          }
                        >
                          <img
                            src={val.image}
                            style={{ width: "347px", height: "200px" }}
                            class="card-img-top"
                            alt={index}
                          />
                          <div class="card-img-overlay ">
                            <div className="card-date font-monospace p-2 w-50">
                              <strong className="month">
                                {moment(val.startDate, "DD-MM-YYYY").format(
                                  "MMMM Do, YYYY"
                                )}{" "}
                              </strong>
                            </div>
                          </div>
                          <div class="card-body">
                            <h5 className="card-tille"> {val.name}</h5>
                            <p class="card-text overflow-hidden">
                              {val.termsCondition}
                            </p>
                            <hr />
                            <a class="card-time">
                              {moment(val.startTime, "HH:mm:ss").format(
                                "hh:mm A"
                              )}
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {!addshow ? (
          <div
            className="d-flex justify-content-center adds-img-styl"
            style={{ marginTop: "4%" }}
          >
            <img src={"../../../home/addsdummy.png"} />
          </div>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default NewEvents;
