import React, { useState, useEffect } from "react";
import AppBar from "../../commonComponents/AppBar";
import axios from "../../axiosInstance";
import { removeToken } from "../../utils/Auth";

import Box from "@mui/material/Box";

import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { sidemenu } from "../../utils/Constants";
import Root from "./UserProfileList/index";
import { useLocation } from "react-router-dom";
const UserProfile = () => {
  const [UserData, setUserData] = useState();
  const [passwordData, setPasswordData] = useState("");
  const [loading, setloading] = useState(true);
  const [activeSideMenu, setactiveSideMenu] = useState(1);
  const [value, setValue] = React.useState(0);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getUserInfo();
    if (location?.state?.num == 2) {
      setactiveSideMenu(2);
    }
    if (location?.state?.num == 3) {
      setactiveSideMenu(3);
    }
    document.title = "User Profile";
  }, []);

  const getUserInfo = async () => {
    let response;
    try {
      response = await axios.get("/user/me");
      if (response?.data?.type === "success") {
        console.log(response?.data?.data, "response?.data?.data");
        setUserData(response?.data?.data);
        await getBookingRequest(response?.data?.data.id);
        setloading(false);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  const getBookingRequest = async (id) => {
    let response;
    try {
      response = await axios.get(`/vehicleBooking/${id}`);
      if (response?.data?.type === "success") {
        console.log(response?.data?.data, "booking");
      }
    } catch (e) {
      console.trace(e);
    }
  };
  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }
  return (
    <>
      <AppBar />
      {loading ? (
        <>
          <Box sx={{ paddingTop: "21%" }} className="d-flex">
            <FacebookCircularProgress />
          </Box>
        </>
      ) : (
        <>
          <section style={{ marginTop: "92px" }}>
            <div className="active_item_container">
              <div class="sidebar">
                {sidemenu?.map((_) => (
                  <>
                    <div
                      onClick={() => {
                        if (_?.text == "Logout") {
                          removeToken();
                        }
                        setactiveSideMenu(_?.id);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flexStart",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {_?.icon}
                      <span>{_?.text}</span>
                    </div>
                  </>
                ))}
              </div>
              <Root
                firstname={UserData?.profile?.firstName}
                lastname={UserData?.profile?.lastName}
                email={UserData?.email}
                phone={UserData?.profile?.phoneNumber}
                addressLine1={UserData?.profile?.addressLine1}
                city={UserData?.profile?.city}
                zipCode={UserData?.profile?.zipCode}
                nationality={UserData?.profile?.nationality}
                numberOfChildren={UserData?.profile?.numberOfChildren}
                spouseEmailAddress={UserData?.profile?.spouseEmailAddress}
                spouseName={UserData?.profile?.spouseName}
                userRole={UserData?.userRole}
                activeSideMenu={activeSideMenu}
                setactiveSideMenu={setactiveSideMenu}
                setUserData={setUserData}
                UserData={UserData}
                passwordData={passwordData}
                setPasswordData={setPasswordData}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default UserProfile;
