import React, { useState, useEffect } from "react";
import AppBar from "../../commonComponents/AppBar";
import { useLocation } from "react-router-dom";
import axios from "../../axiosInstance";

import {
  aboutPassaMainParagraphOne,
  aboutPassaMainParagraphTwo,
  aboutPassaMainParagraphThree,
  aboutPassaMainParagraphFour,
  aboutPassaMainParagraphFive,
  aboutPassaMissionAndVisionParaOne,
  aboutPassaMissionAndVisionParaTwo,
  PassaConstitution,
  StateObj,
} from "../../utils/Constants";
const AboutPaasa = () => {
  const [adds, setadds] = useState();
  useEffect(() => {
    getadds();
    document.title = "About Paasa";
  }, []);

  const location = useLocation();
  const getadds = async () => {
    let response;
    try {
      response = await axios.get(`/advertisement${location.pathname}`);
      if (response?.data?.type === "success") {
        console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };

  return (
    <>
      <AppBar />
      <section className="p-t120 ">
        <div className="wt-bnr-inr m-b20  p-t50">
          <div className="container-lg">
            <div className="banner-title-name p-tb20">
              <h1 className="wt-title">About PAASA</h1>
            </div>

            <div className="banner-image">
              <img className="rounded" src="./about/header2.png" alt="" />
            </div>
          </div>
        </div>
        <div className="page-contant">
          <div className="container-lg">
            <div className="para">
              <p className="text-align-justify">{aboutPassaMainParagraphOne}</p>
              <p className="text-align-justify">{aboutPassaMainParagraphTwo}</p>
              <p className="text-align-justify">
                {aboutPassaMainParagraphThree}
              </p>
              <p className="text-align-justify">
                {aboutPassaMainParagraphFour}
              </p>
              <p className="text-align-justify">
                {aboutPassaMainParagraphFive}
              </p>
            </div>
            <div className="para">
              <b className="green-text">Mission and Goals</b>
              <p className="text-align-justify">
                PAASA's mission is to promote Pakistani culture and values
                within an integrated and connected Australian community and
                continue to be a part of multicultural and ethnic society of
                South Australia.
              </p>
            </div>
            <div className="para">
              <b className="green-text">PAASA’s primary goals are:</b>
              <p className="text-align-justify">
                To promote Pakistani cultural and linguistic diversity.
                <br />
                To encourage participation in community volunteer and charitable
                activities
                <br />
                To increase awareness about Pakistan and Pakistani culture in
                the local community
                <br />
                To promote leadership, volunteerism, sports, and educational
                activities for its youth members
              </p>
            </div>
          </div>
          <div className="container-lg">
            <div className="wt-bnr-inr">
              <div className="banner-title-name p-tb10">
                <h1 className="wt-title">Our Mission & Vision</h1>
              </div>
              <div className="col-xl-5 side-image d-sm-block d-md-none img-fluid ">
                <div className="side-img">
                  <img src="./about/mission-vision.png" alt="" />
                </div>
              </div>
            </div>{" "}
            <div className="row">
              <div className="col-xl-7">
                <div className="para">
                  <p className="text-align-justify">
                    {aboutPassaMissionAndVisionParaOne}
                  </p>
                  <p className="text-align-justify">
                    {aboutPassaMissionAndVisionParaTwo}
                  </p>
                </div>
              </div>
              <div className="col-xl-5 d-none d-md-block">
                <div className="side-img">
                  <img src="./about/mission-vision.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-lg">
            <div className="wt-bnr-inr">
              <div className="banner-title-name p-tb10">
                <h1 className="wt-title">PAASA Constitution</h1>
              </div>
              <div className="col-xl-5 d-sm-block d-md-none img-fluid ">
                <div className="side-img">
                  <img src="./about/constitution.png" alt="" />
                </div>
              </div>
            </div>{" "}
            <div className="row">
              <div className="col-xl-7">
                <div className="para">
                  <p>{PassaConstitution}</p>
                  <p>{PassaConstitution}</p>
                  <p>{PassaConstitution}</p>
                </div>
              </div>
              <div className="col-xl-5 d-none d-md-block">
                <div className="side-img">
                  <img src="./about/constitution.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-lg">
            <div className="wt-bnr-inr">
              <div className="banner-title-name p-tb10">
                <h1 className="wt-title">PAASA Strategic Objectives</h1>
              </div>
            </div>{" "}
            <div className="row">
              <div className="col-xl-12">
                <div className="para">
                  <div className="about-StatObj">
                    {StateObj.map((val, index) => {
                      return (
                        <>
                          <div className="list">
                            <div className="dot"></div>
                            <p>{val.para}</p>{" "}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex adds-img-styl">
          <img src={adds?.picture ?? "../../../home/addsdummy.png"} />
        </div>
      </section>
    </>
  );
};

export default AboutPaasa;
