import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import DonateUsPaymentCard from '../components/DonateUsPaymentCard/DonateUsPaymentCard';

import InputComponent from '../commonComponents/Input/InputComponent';
import Label from '../commonComponents/Label/Label';

import { loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';
// import axios from '../axiosInstance';

// import { toast } from 'react-toastify';
import { Store } from '../StoreContext';

import {  appConfig } from "../utils/Constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const stripePromise = loadStripe(appConfig.stripeKey);
export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  const [steps, setSteps] = useState('1');
  const store = Store();
  useEffect(() => {
    setFormData({
      name: store?.profileInfo?.profile?.firstName,
      email: store?.profileInfo?.email,
      phoneNumber: store?.profileInfo?.profile?.phoneNumber,
    });
  }, [store]);
  const [formData, setFormData] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setFormData({ ...formData, ...obj });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData?.name && formData?.email && formData?.phoneNumber && formData?.amount) {
      setSteps(2);
    } else {
      return;
    }
    // let obj = {
    //   amount: formData?.amount,
    //   email: formData?.email,
    // };
    // let response;
    // try {
    //   response = await axios.post("/payment/createDonatePayment", obj);
    //   if (response?.data?.type == "success") {
    //     toast.success("Data Saved Successfully");
    //     setSteps("2");
    //   }
    // } catch (e) {
    //   console.trace(e);
    //   toast.error(e?.response?.data?.message);
    // }
  };
  return (
    <>
      <button className="btn btn-green" onClick={handleClickOpen}>
        $ Donate us
      </button>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>

        <div className="long-form donation-dialog">
          <div className="container-lg">
            <div className="form-heading">
              <h4>Donate us</h4>
            </div>
            {/* <div className="para">
              Lorem Ipsum is simply dummy text of the printing antypesetting
              industry.
            </div> */}
            {steps == '1' ? (
              <>
                <form onSubmit={handleSubmit}>
                  <div class="row">
                    <div className="col-lg-12">
                      <Label text="Name" />
                      <InputComponent
                        value={formData?.name}
                        required={true}
                        type="text"
                        id="name-donate-us"
                        placeholder="Enter Name"
                        className="form-control"
                        onChange={(e) => _setState('name', e.target.value)}
                      />

                      <Label text="Email" />
                      <InputComponent
                        value={formData?.email}
                        required={true}
                        type="email"
                        id="email-donate-us"
                        placeholder="Enter Email"
                        className="form-control"
                        onChange={(e) => _setState('email', e.target.value)}
                      />
                      <Label text="Phone No" />
                      <InputComponent
                        value={formData?.phoneNumber}
                        required={true}
                        type="number"
                        id="number-donate-us"
                        placeholder="Enter Phone No"
                        className="form-control"
                        onChange={(e) => _setState('phoneNumber', e.target.value)}
                      />
                      <Label text="Amount" />
                      <InputComponent
                        required={true}
                        type="number"
                        id="inputamount"
                        placeholder="Enter amount"
                        className="form-control"
                        onChange={(e) => _setState('amount', e.target.value)}
                      />

                      <button type="submit" className="btn btn-green ">
                        Proceed to payment
                      </button>
                    </div>
                  </div>
                </form>
              </>
            ) : null}
            {steps == '2' ? (
              <>
               <Elements stripe={stripePromise}>
                  <DonateUsPaymentCard formData={formData} setFormData={setFormData} setSteps={setSteps}  onFailedPayment={handleClose} />
                </Elements>
              </>
            ) : null}
          </div>
        </div>
      </BootstrapDialog>
    </>
  );
}
