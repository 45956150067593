import React, { useMemo, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from "../../../axiosInstance";
import CustomAlert from "../../../commonComponents/Modal/CustomAlert";

import { toast } from "react-toastify";

import { RemoveDataInCartArray } from "../../../utils/Helpers";

import useResponsiveFontSize from "../../../commonComponents/useResponsiveFontSize";

import Swal from "sweetalert2";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};
const CartPaymentCard = ({ formData }) => {
  const [showAlert, setShowAlert] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    //Client Sectet Get from Server:
    try {
      let data = {
        amount: formData?.amountCharged,
      };
     const response = await axios.post("/payment/createPayment", data);
      if (response?.data?.type == "success") {
        const clientSecret = response?.data?.data?.clientSecret;
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: { card: elements.getElement(CardElement) },
        });
        if (paymentIntent?.status === "succeeded") {
          try {
           const response = await axios.post(
              "/assetMangement/assetBooking/addBooking",
              formData
            );
            if (response?.data?.type == "success") {
              Swal.fire("Booking Processed", "Data Saved Successfully", "success");
              // toast.success("Payment Successfull");
              // Swal.fire("Done", "Payment Successfull", "success");
              setShowAlert(true);
              RemoveDataInCartArray();
              setTimeout(() => {
                setShowAlert(false);
                window.location = "/AboutPaasa";
              }, 2000);
            }
            else if (response?.data?.type == "error") {
              Swal.fire("Booking Processing Failed", "Booking Data Saving Failed", "error");
            }
          } catch (e) {
            Swal.fire("Booking Processing Failed", e?.response?.data?.message, "error");
          }
        }
        else if (error?.message) {
          Swal.fire("Payment Processing Failed", error?.message, "error");
        }
        else {
          Swal.fire("Payment Processing Failed", paymentIntent?.cancellation_reason, "error");
        }
      }
    } catch (e) {
      Swal.fire("Payment Processing Failed", e?.response?.data?.message, "error");
    }
  };
  return (
    <>
      {showAlert ? <CustomAlert /> : <></>}
      <form className="p-a20" onSubmit={handleSubmit}>
        <label className="w-100 parent-label">
          Card details
          <CardElement
            options={options}
            onReady={() => {
              console.log("CardElement [ready]");
            }}
            onChange={(event) => {
              console.log("CardElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardElement [blur]");
            }}
            onFocus={() => {
              console.log("CardElement [focus]");
            }}
          />
        </label>
        <button type="submit" disabled={!stripe}>
          Pay
        </button>
      </form>
    </>
  );
};

export default CartPaymentCard;
