import {Outlet, Navigate} from 'react-router'
import Login from './Login'

const useAuth = ()=>{
    let userdata = localStorage.getItem("userData");
    userdata = JSON.parse(userdata);
  const user = {loggedIn:userdata?.usertoken}
 return user && user?.loggedIn
}

const ProtectedRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet/> : <Navigate to='/LogIn'/>
}

export default ProtectedRoutes