import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from "@mui/material/styles";
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const DialogBox = ({ 
    handleClickOpenDialog,
    handleCloseDialog,
    openDialog,
}
) => {

  return (
   <>
   <div>
      <Dialog
       TransitionComponent={Transition}
       keepMounted
      sx={{padding:"2%"}}
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          I agree and accept the terms and policies
          </DialogContentText>
        </DialogContent>
        <DialogActions
        >
          <Button onClick={()=>handleCloseDialog("disagree")}>Disagree</Button>
          <Button onClick={()=>handleCloseDialog("agree")} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>

   </>
  )
}

export default DialogBox