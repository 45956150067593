import React, { useState, useEffect } from "react";
import AppBar from "../../../commonComponents/AppBar";
import BookingFormCart from "./BookingFormCart";
import CartPayment from "./CartPayment";

import { BootstrapDialog } from "../../../utils/Constants";
import { BootstrapDialogTitle } from "../../../utils/Constants";
import { getCartArray, setDataInCartArray } from "../../../utils/Helpers";
import DialogBox from "../../../commonComponents/DialogBox/DialogBox";
import PropTypes from "prop-types";

import axios from "../../../axiosInstance";
import { getTotal } from "../../../utils/Constants";

import DeleteIcon from "@mui/icons-material/Delete";
import { Store } from "../../../StoreContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ViewCart = () => {
  const [open, setOpen] = useState(false);
  const [Steps, setSteps] = useState("0");
  const [formData, setFormData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [CartData, setCartData] = useState();
  const store = Store();
  let localStorageCartArray = getCartArray();
  useEffect(() => {
    setCartData(localStorageCartArray);
    getUserInfo();
  }, []);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  let total = getTotal(localStorageCartArray);
  const handleCloseDialog = (props) => {
    // console.log(props, 'props');
    // if (props == 'agree') {
    //   setFormData({
    //     ...formData,
    //     isAcceptedTerms: true,
    //   });
    // }
    // if (props == 'disagree') {
    //   setFormData({
    //     ...formData,
    //     isAcceptedTerms: false,
    //   });
    // }
    // if (props == 'cancel_booking') {
    //   setFormData({});
    // }
    setOpenDialog(false);
  };
  const getUserInfo = async () => {
    let response;
    try {
      response = await axios.get("/user/me");
      if (response?.data?.type === "success") {
        console.log(response);
        setFormData((pre) => {
          return {
            ...pre,
            firstName: response?.data?.data?.profile?.firstName,
            lastName: response?.data?.data?.profile?.lastName,
            phoneNumber: response?.data?.data?.profile?.phoneNumber,
            address: response?.data?.data?.profile?.addressLine1,
            email: response?.data?.data?.email,
            isAcceptedTerms: response?.data?.data?.isAcceptedTerms,
            amountCharged: total,
            bookings: store?.cartdata,
          };
        });
      }
    } catch (e) {
      console.trace(e);
    }
  };
  const deleteItem = (id) => {
    console.log(localStorageCartArray, id);
    let newArray = localStorageCartArray?.filter((_) => _?.assetId != id);
    setDataInCartArray(newArray);
    total = getTotal(newArray);
    localStorageCartArray = getCartArray();
    setCartData(localStorageCartArray);
  };
  console.log(CartData, "CartData");
  return (
    <>
      <AppBar />
      <section className="p-t120">
        <div class="courses-container-urdu-school flex-col align_items">
          {CartData?.map((val) => (
            <>
              <div style={{ padding: "1%" }} class="course-urdu-school">
                <div className="flex-col">
                  <div className="d-flex w-100 space-between">
                    <span>Asset name</span>
                    <span>{val?.assetName}</span>
                  </div>
                  <div className="d-flex w-100 space-between">
                    <span>Start Date</span>
                    <span>{val?.startDate}</span>
                  </div>
                  <div className="d-flex w-100 space-between">
                    <span>End date</span>
                    <span>{val?.endDate}</span>
                  </div>

                  <div className="d-flex w-100 space-between">
                    <span>Rent Per Day</span>
                    <h6>${val?.RentPerDay}</h6>
                  </div>
                  <div className="d-flex w-100 space-between">
                    <span>Booking Price</span>
                    <h6>${val?.bookingPrice}</h6>
                  </div>
                  <div className="d-flex w-100 space-between">
                    <span>Security Deposit</span>
                    <h6>${val?.securityDeposit}</h6>
                  </div>
                  <div className="d-flex w-100 space-between">
                    <span>Price + security deposit</span>
                    <h6>${val?.totalPriceWithSecurity}</h6>
                  </div>
                  <div className="d-flex w-100 space-between">
                    <DeleteIcon
                      onClick={() => deleteItem(val?.assetId)}
                      sx={{ color: "red" }}
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
          <div
            style={{ flexDirection: "row", width: "45%", padding: "1%" }}
            className="courses-container-urdu-school course-urdu-school d-flex space-between"
          >
            <div
              style={{ flexDirection: "row", width: "100%", padding: "1%" }}
              className="courses-container-urdu-school course-urdu-school d-flex space-between"
            >
              <span>Total Booking Price</span>
              <span>
                $
                {CartData?.reduce(
                  (acc, o) => acc + parseInt(o?.bookingPrice),
                  0
                )}
              </span>
            </div>

            <div
              style={{ flexDirection: "row", width: "100%", padding: "1%" }}
              className="courses-container-urdu-school course-urdu-school d-flex space-between"
            >
              <span>Total Security Deposit</span>
              <span>
                $
                {CartData?.reduce(
                  (acc, o) => acc + parseInt(o?.securityDeposit),
                  0
                )}
              </span>
            </div>
            <div
              style={{ flexDirection: "row", width: "100%", padding: "1%" }}
              className="courses-container-urdu-school course-urdu-school d-flex space-between"
            >
              <span>Total Amount</span>
              <span>${total}</span>
            </div>
          </div>
        </div>

        <div className="w-100 text-center">
          <div className="col-12">
            <button
              onClick={() => {
                if (localStorageCartArray.length != 0) {
                  setSteps("1");
                  setOpen(true);
                } else {
                  // toast.error("No items in the cart");
                  Swal.fire("Error", "No items in the cart!", "error");
                }
              }}
              className="btn btn-green pay-btn"
            >
              Proceed to payment
            </button>
          </div>
        </div>
        <div className="w-50">
          <BootstrapDialog
            maxWidth="1000"
            fullWidth={true}
            //onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogBox
              maxWidth="lg"
              handleClickOpenDialog={handleClickOpenDialog}
              handleCloseDialog={handleCloseDialog}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
            />
            {Steps === "1" ? (
              <>
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={() => setOpen(false)}
                ></BootstrapDialogTitle>
                <BookingFormCart
                  formData={formData}
                  setSteps={setSteps}
                  setFormData={setFormData}
                />
              </>
            ) : (
              <></>
            )}
            {Steps === "2" ? (
              <>
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={() => setSteps("1")}
                ></BootstrapDialogTitle>
                <CartPayment
                  formData={formData}
                  setSteps={setSteps}
                  setFormData={setFormData}
                />
              </>
            ) : (
              <></>
            )}

            <DialogBox />
          </BootstrapDialog>
        </div>
      </section>
    </>
  );
};

export default ViewCart;
