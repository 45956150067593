import React, { useEffect, useState } from "react";
import AppBar from "../../commonComponents/AppBar";
import { Link } from "react-router-dom";

export const ResetPasswordConfirmation = () => {
  return (
    <>
      <AppBar />
      <div className="form-contan ">
        <div className="long-form  logIn-form ">
          <div className="container-lg">
          <div className="form-heading ">
              <h4>Reset Password Successful</h4>
            </div>
            <div className="para">
              <p>
                Thank you, your password has been reset.
              </p>
              <p>
                {/* If you do not receive an email. please check your spam folder. */}
                 If you require any further assistance please email at <a href="mailto:ec@passa.org.au">ec@passa.org.au</a>
              </p>
            </div>
            <div className="info-link">
              <Link to="/Login">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
