import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useLocation } from "react-router-dom";

import Footer from "./commonComponents/Footer";
import Home from "./components/Home/Home";
import CommunityVehicle from "./components/BookingVehicale/CommunityVehicle";
import AboutPaasa from "./components/About/AboutPaasa";
import CovidInfo from "./components/CovidInfo/CovidInfo";

import LogIn from "./components/JoinIn/Login";
import SignUp from "./components/JoinIn/SignUp";
import MembershipPlan from "./components/JoinIn/MembershipPlan";
import MemberDetail from "./components/JoinIn/MemberDetail";
import Payment from "./components/JoinIn/Payment";
import ManagementCommittee from "./components/ManagementCommittee/ManagementCommittee";
import UserProfile from "./components/UserProfile/UserProfile";
import UrduSchool from "./components/Home/UrduSchool";
import ContactUs from "./components/Home/ContactUs";
import MeetTheCommunity from "./components/Home/MeetTheCommunity";
import StrategicObjective from "./components/Home/StrategicObjective";
import CovidVaccInfo from "./components/Home/CovidVaccInfo";
import SportsPrograms from "./components/Home/SportsPrograms";
import NewEvents from "./components/Home/NewEvents";
import BookingVehicalePayment from "./components/BookingVehicale/BookingVehicalePayment";
import AssetManagement from "./components/AssetManagement/Index";
import ViewCart from "./components/AssetManagement/Cart/ViewCart";
import ForgetPassword1 from "./components/ForgetPassword/ForgetPassword1";
import SetPassword from "./components/ForgetPassword/SetPassword";
import {ResetPasswordConfirmation} from "./components/ForgetPassword/SetPasswordConfirmation";


import ProtectedRoutes from "./components/JoinIn/ProtectedRoutes";

import SetAuthToken from "./SetAuthToken";

import { ToastContainer, Flip, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReserveEventCard from "./components/EventManagement/Cart/ReserveEventCard";
import EventCards from "./components/EventManagement/Cart/EventCards";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      let parseUserData = JSON.parse(userData ?? '{}');
      if (parseUserData?.usertoken) {
        SetAuthToken(parseUserData?.usertoken);
      }
    }
  }, []);

  React.useEffect(() => {
    document.title = "Paasa";
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        transition={Flip}
        limit={1}
      />
      <Routes>
        <Route index element={<Home />} />
        {/* protected Routes */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/AssetManagement" element={<AssetManagement />} />
          <Route path="/ViewCart" element={<ViewCart />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/MemberDetail" element={<MemberDetail />} />
          <Route path="/MembershipPlan" element={<MembershipPlan />} />
        </Route>
        {/* protected Routes */}
        <Route path="/ManagementCommittee" element={<ManagementCommittee />} />
        <Route
          path="/BookingVehicalePayment"
          element={<BookingVehicalePayment />}
        />

        <Route path="/BookVehicle" element={<CommunityVehicle />} />
        <Route path="/ReserveEvent" element={<ReserveEventCard />} />
        <Route path="/EventCards" element={<EventCards />} />
        <Route path="/AboutPaasa" element={<AboutPaasa />} />
        <Route path="/Covid19Info" element={<CovidInfo />} />
        <Route path="/Contactus" element={<ContactUs />} />
        <Route path="/LogIn" element={<LogIn />} />
        <Route path="/SignUp" element={<SignUp />} />

        <Route path="/UrduSchool" element={<UrduSchool />} />
        <Route path="/Meetthecommunity" element={<MeetTheCommunity />} />
        <Route path="/StrategicObjective" element={<StrategicObjective />} />
        <Route path="/CovidVaccInfo" element={<CovidVaccInfo />} />
        <Route path="/SportsPrograms" element={<SportsPrograms />} />
        <Route path="/NewsAndEvents" element={<NewEvents />} />
        <Route path="/NewsAndEvents" element={<NewEvents />} />
        <Route path="/ForgetPassword" element={<ForgetPassword1 />} />
        <Route path="/SetPassword" element={<SetPassword />} />
        <Route path="/SetPasswordConfirmation" element={<ResetPasswordConfirmation/>} />
        {/* <Route path="/FuneralFund" element={<FuneralFund />} /> */}
      </Routes>
      <Footer />
    </>
  );
};

export default App;
