import React, { useState, useEffect } from "react";
import AppBar from "../../commonComponents/AppBar";
import axios from "../../axiosInstance";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

const WelcomToPaasa = ({ addshow }) => {
  const [adds, setadds] = useState();
  useEffect(() => {
    getadds();
  }, []);
  const getadds = async () => {
    let response;
    try {
      response = await axios.get(`/advertisement/AboutPaasa`);
      if (response?.data?.type === "success") {
        console.log(response?.data?.data);
        setadds(response?.data?.data);
      }
    } catch (e) {
      console.trace(e);
    }
  };
  return (
    <>
      <section>
        <div className="container-lg welcome-parah">
          <div className="wt-bnr-inr">
            <div className="home-title-name p-tb10">
              <h1 className="wt-title">Welcome to PAASA</h1>
            </div>
              <div className="side-img  d-sm-block d-md-none img-fluid img-thumbnail">
                <img src="./home/about1.png" alt="" />
              </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="home-para">
                <div class="text-justify">
                  This was Dr Ashfaque Ahmed who came to Australia in 1962 in
                  Sydney and then moved to Adelaide to work in The Royal
                  Adelaide hospital in 1965, decided to start a Pakistani
                  Association. His idea finally came into existence in 1967 with
                  the name as Australian Pakistani Association. Dr. Ashfaque
                  Ahmed was the first President with Vice President Captain
                  Sabir Shah of the Pakistani Army, and treasurer Mr. Azhar
                  Jamal Minhas from Lahore. Later in 1984 that association
                  become Pakistani Australian Association of SA- PAASA with Mr.
                  Shamim Noori as President, Dr. Khalid Farooqi as Secretary and
                  Dr. Farooq as Treasurer. Since then PAASA has been
                  representing Pakistani community in South Australia on social
                  and cultural platforms and providing platform to the community
                  members to not only celebrate their events of significant
                  importance but also to showcase our ethnic diversity, unique
                  culture and social and religious values to the multicultural
                  communities in SA.
                </div>
              </div>
            </div>
            <div className="col-lg-5  d-none d-md-block">
              <div className="side-img">
                <img src="./home/about1.png" alt="" />
              </div>
            </div>
          </div>
          <div className="info-link">
            <Link to="/AboutPaasa">
              See more information <ArrowForwardIcon />
            </Link>
          </div>
        </div>
        <div className="d-none d-sm-flex clippy">
          <div className="green-clip"></div>
          <div className="blue-clip"></div>
        </div>
        {/* {
          <div className="d-flex adds-img w-80">
            <img style={{ height: '400px' }} src={adds?.picture} />
          </div>
        } */}
      </section>
    </>
  );
};

export default WelcomToPaasa;
