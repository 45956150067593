import React, { useState, useEffect } from "react";
import AppBar from "../../commonComponents/AppBar";
import axios from "../../axiosInstance";
import { getCartArray, setDataInCartArray } from "../../utils/Helpers";
import {
  assetHireTextParagraphOne,
  assetHireTextParagraphTwo,
  assetHireTextParagraphThree,
} from "../../utils/Constants";
import AssetsCards from "./AssetsCards";
import { Store } from "../../StoreContext";
import { UpdateStore } from "../../StoreContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AssetManagement = () => {
  const store = Store();
  const updateStore = UpdateStore();
  useEffect(() => {
    getAssets();
  }, []);
  const [AssetsData, setAssetsData] = useState([]);
  const [CartData, setCartData] = useState();
  const [formData, setFormData] = useState({});
  const [Steps, setSteps] = useState("0");
  const [Open, setOpen] = useState(false);
  let localStorageCartArray = getCartArray();
  const getAssets = async () => {
    let response;
    try {
      response = await axios.get("/assetMangement/asset/list");
      console.log(response, "lop");
      setAssetsData(response?.data?.data);
    } catch (e) {
      console.trace(e);
    }
  };
  const addToCart = async (id, assetName) => {
    const length = localStorageCartArray?.filter((_) => _?.assetId == id);
    let obj = {
      startDate: formData?.startDate ?? localStorageCartArray[0]?.startDate,
      endDate: formData?.endDate ?? localStorageCartArray[0]?.endDate,
      assetId: id,
      name: assetName,
    };
    let response;
    if (length != 0) {
      // toast.error("Item already exist");
      Swal.fire("Error", "Item already exist!", "error");
      return;
    }
    console.log(localStorageCartArray, "localStorageCartArray");
    if (
      (formData?.startDate && formData?.endDate) ||
      localStorageCartArray.length > 0
    ) {
      try {
        response = await axios.post(
          "/assetMangement/assetBooking/addAssetToBundle",
          obj
        );
        if (response?.data?.type === "success") {
          const obj = {
            assetId: id,
            endDate: formData?.endDate ?? localStorageCartArray[0]?.endDate,
            startDate:
              formData?.startDate ?? localStorageCartArray[0]?.startDate,
            totalPriceWithSecurity:
              response?.data?.data?.totalPriceWithSecurity,
            assetName: assetName,
            RentPerDay: response?.data?.data?.perDayPrice,
            bookingPrice: response?.data?.data?.bookingPrice,
            securityDeposit: response?.data?.data?.securityDeposit,
          };
          updateStore({
            cartdata: [...store.cartdata, obj],
          });

          localStorageCartArray.push(obj);
          let keys = ["assetId"];
          let filtered = localStorageCartArray.filter(
            (
              (s) => (o) =>
                ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
            )(new Set())
          );
          setDataInCartArray(filtered);
          setCartData(response?.data?.data);
          //setFormData({});
          // toast.success("Item sucessfully added to cart");
          Swal.fire(
            "added sucessfully!",
            "Item sucessfully added to cart",
            "success"
          );
          setSteps("0");
          setOpen(false);
          getAssets();
        }
      } catch (e) {
        console.trace(e);
      }
    }
  };
  console.log(AssetsData, "AssetsData");
  return (
    <>
      <AppBar />
      <section className="p-t70">
        <div className="wt-bnr-inr  m-b40 p-t100">
          <div className="container-lg">
            <div className="banner-title-name p-tb20">
              <h1 className="wt-title">Asset Management</h1>
            </div>

            <div className="banner-image">
              <img className="rounded" src="./assethire.png" alt="" />
            </div>
          </div>
        </div>
        <div className="page-contant">
          <div className="container-lg">
            <div className="para">
              <p className="text-align-justify">{assetHireTextParagraphOne}</p>
              <p className="text-align-justify">{assetHireTextParagraphTwo}</p>
              <p className="text-align-justify">
                {assetHireTextParagraphThree}
              </p>
            </div>
          </div>
          <div className="container-lg">
            <AssetsCards
              addToCart={addToCart}
              AssetsData={AssetsData}
              Steps={Steps}
              setSteps={setSteps}
              Open={Open}
              setOpen={setOpen}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default AssetManagement;
