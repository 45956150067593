import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import BookingForm from './BookingForm';
import Bookcharges from './Bookcharges';
import BookingVehicalePayment from './BookingVehicalePayment';

import DialogBox from '../../commonComponents/DialogBox/DialogBox';

import { getToken } from '../../utils/Auth';

import { useNavigate } from 'react-router-dom';

import { BootstrapDialog } from '../../utils/Constants';
import { BootstrapDialogTitle } from '../../utils/Constants';

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));

// const BootstrapDialogTitle = (props) => {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}

//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             // color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// };

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const VehicleCards = ({
  VehicleData,
  formData,
  setFormData,
  getUserInfo,
  getVehicles,
  calculateRent,
  BillingData,
  ProceedToPayment,
  Steps,
  setSteps,
  Open,
  setOpen,
  vehicleFormData,
  setVehicleFormData,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const token = getToken();

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (props) => {
    console.log(props, 'props');
    if (props == 'agree') {
      setFormData({
        ...formData,
        isAcceptedTerms: true,
      });
    }
    if (props == 'disagree') {
      setFormData({
        ...formData,
        isAcceptedTerms: false,
      });
    }
    if (props == 'cancel_booking') {
      setFormData({});
    }
    setOpenDialog(false);
  };

  const handleClickOpen = (key, id, values) => {
    console.log(values);
    if (token) {
      setOpen(true);
      setSteps('1');
      const obj = {};
      obj[key] = id;
      setFormData({ ...formData, ...obj, formValues: values });
    } else {
      navigate('/LogIn');
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSteps('1');
    setFormData({});
  };
  return (
    <>
      <div className="row justify-content-md-center">
        <h1 className="pg-title text-center">Available Vehicle</h1>
        {VehicleData?.map((value, index) => {
          return (
            <div class=" col-xl-3 col-sm-6 col-12">
              <div class="card text-center">
                <img class="card-img-top " src={value?.image} alt="Card cap" />
                <div class="card-body">
                  <p class="card-title">{value?.name}</p>
                  <p>
                    Model: {value?.model} Seats:{value?.seatingCapacity}{' '}
                  </p>
                  <p class="card-title">${value?.dailyRent}/day </p>

                  <button
                    id={value?.id}
                    onClick={() => {
                      handleClickOpen('vehicleId', value?.id, value);
                      setVehicleFormData(value);
                      getUserInfo();
                    }}
                    className="btn btn-green "
                  >
                    Book now
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <BootstrapDialog
        //onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Open}
      >
        <DialogBox
          handleClickOpenDialog={handleClickOpenDialog}
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />

        {Steps === '1' ? (
          <>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
            <div className="long-form ">
              <div className="container-lg">
                <div className="form-heading">
                  <h4>Book a Vehicle</h4>
                </div>
                <div class="row justify-content">
                  <BookingForm
                    formData={formData}
                    setFormData={setFormData}
                    setOpenDialog={setOpenDialog}
                    setSteps={setSteps}
                    vehicleFormData={vehicleFormData}
                    calculateRent={calculateRent}
                    VehicleData={VehicleData?.filter((val) => val?.id == formData?.vehicleId)}
                  />
                  {/* <div className="col-lg-6 ">
                      <input
                        type="text"
                        class="form-control "
                        id="inputDiverName"
                        placeholder="Name of driver"
                      />
                    </div> */}

                  <DialogActions></DialogActions>
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        {Steps === '2' ? (
          <>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setSteps('1')}></BootstrapDialogTitle>

            <Bookcharges BillingData={BillingData} ProceedToPayment={ProceedToPayment} setSteps={setSteps} />
            <div className="cnl-contain">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <DialogActions>
                      <button onClick={handleClose} className="btn  cancel-btn">
                        Cancel Booking
                      </button>
                    </DialogActions>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        {Steps === '3' ? (
          <>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setSteps('1')}></BootstrapDialogTitle>

            <BookingVehicalePayment
              BillingData={BillingData}
              formData={formData}
              ProceedToPayment={ProceedToPayment}
              getVehicles={getVehicles}
              setSteps={setSteps}
              setOpen={setOpen}
            />
            <div className="cnl-contain">
              <div className="container">
                <div className="row">
                  <div style={{ marginLeft: '3.5%' }} className="col-12">
                    <DialogActions>
                      <button onClick={handleClose} className="btn  cancel-btn">
                        Cancel Booking
                      </button>
                    </DialogActions>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </BootstrapDialog>
    </>
  );
};

export default VehicleCards;
