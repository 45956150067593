import React, { useState } from "react";
import Slider from "react-slick";

const images = [
  {
    img: "./header/slide1.png",
  },
  {
    img: "./header/slide2.png",
  },
  {
    img: "./header/slide3.png",
  },
  {
    img: "./header/slide4.png",
  },
  {
    img: "./header/slide5.png",
  },
];
const Slider3D = ({ sliderData }) => {
  const [ImageIndex, setImageIndex] = useState(0);
  const settings = {
    autoplay:true,
    autoplaySpeed:2500,
    infinity: true,
    lazyload: true,
    speed: 300,
    slidesToShow:
      sliderData?.length > 4
        ? 3
        : sliderData?.length > 2
        ? sliderData?.length - 1
        : 1,
    centerMode: true,
    centerPadding: 0,
    dots: true,
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
  };
  console.log(sliderData, "sliderData");
  return (
    <>
      <div className="header-slider">
        <Slider {...settings}>
          {sliderData?.map((value, index) => {
            return (
              <div
                key={index}
                className={
                  index === ImageIndex && sliderData?.length > 2
                    ? "slide activeSlide"
                    : "slide"
                }
              >
                <img
                  style={{ width: sliderData?.length != 3 ? "100%" : "5%" }}
                  src={value.picture}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default Slider3D;
