import React from "react";
import axios from "../../../axiosInstance";
import Table from "../../../utils/table/Table";

const BookingRequest = ({ UserData }) => {
  console.log(UserData, "UserData");
  const [data, setData] = React.useState([]);
  const getCurrentBookings = async () => {
    const response = await axios.get(
      "/eventMangement/ticketBooking/me?limit=10&page=1"
    );
    console.log(response?.data?.data, "response");
    setData(response?.data?.data);
  };
  React.useEffect(() => {
    getCurrentBookings();
  }, []);
  return (
    <>
      <Table
        tableData={data}
        headings={[
          "name",
          "location",
          "startDate",
          "endDate",
          "price",
          "Actions",
        ]}
      />
    </>
  );
};

export default BookingRequest;
