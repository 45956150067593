import React, { useMemo, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "../../axiosInstance";

import useResponsiveFontSize from "../../commonComponents/useResponsiveFontSize";
import { useNavigate } from "react-router-dom";

import CustomAlert from "../../commonComponents/Modal/CustomAlert";
import { getPlanActiveEndDate } from "../../utils/Helpers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const PaymentCard = ({ state }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  // React.useEffect(() => {
  //   // console.log(state, "state");
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    //Client Sectet Get from Server:
    let data = {
      amount: state?.packagePrice,
      description: state?.packageName,
    };
    let response;
    try {
      console.clear();
      const localCardElement = CardElement;
      setLoading(true)
      // console.log(state, "this is state");
      response = await axios({
        method: "post",
        url: "/payment/createPayment",
        data,
      });
      if (response?.data?.type == "success") {
        setLoading(false);
        const clientSecret = response?.data?.data?.clientSecret;
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: { card: elements.getElement(localCardElement) },
        });
        setLoading(true);
        if (paymentIntent?.status === "succeeded") {
          //Save User plan Activation api call
          let data = {
            spouseName: state?.spouseName,
            spouseEmailAddress: state?.spouseEmailAddress,
            numberOfChildren: parseInt(state?.numberOfChildren),
            studyingCourse: state?.studyingCourse,
            childernAges: state?.childernAges,
          };
    
          try {
            await axios({
              method: "post",
              url: "/plans/userPlanActivation",
              data: {
                planId: state?.planId,
                startDate: moment().format("DD-MM-YYYY"),
                endDate: getPlanActiveEndDate()
              }
            });
            try {
              response = await axios({
                method: "patch",
                url: "/user/updateProfile",
                data
              });
              if (response?.data?.type == "success") {
                setLoading(false);
                Swal.fire("Selected plan activation confirmation",` ${state?.packageName} plan is active on your profile.`, "success");
                // setShowAlert(true);
                setTimeout(() => {
                  // setShowAlert(false);
                  navigate("/AboutPaasa");
                }, 2000);
              }
            } catch (e) {
              setLoading(false);
              Swal.fire("Plan activation confirmation", "Plan active but profile dara is not updated" ||error?.response?.data?.message, "error");
              // Swal.fire("Error", e?.response?.data?.message, "error");
            }
          } catch (error) {
            setLoading(false);
            Swal.fire("Plan activation failed", error?.response?.data?.message, "error");
          }
      
        }
        else if (error?.message) {
          setLoading(false)
          Swal.fire("Payment processing failed", error?.message, "error");
        }
        else {
          setLoading(false)
          Swal.fire("Payment processing failed ", paymentIntent?.cancellation_reason, "error");
          //show user to cancel reason {paymentIntent.cancellation_reason}
        }
        // toast.success(response?.data?.message);
        // Swal.fire("Done", response?.data?.message, "success");
      }
    } catch (e) {
      setLoading(false)
      // toast.error(e.response.data.message);
      Swal.fire("Payment processing failed", e?.response?.data?.message, "error");
    }
    // console.log(paymentIntent?.status);
    // console.log(paymentIntent);
  };
  // console.log(state, "state");
  return (
    <>
      {/* {showAlert ? <CustomAlert /> : <></>} */}
      {loading ? (
        <div className="d-flex">
          <CircularProgress size="20vh" />
        </div>
      ) : (
        <form type="submit" onSubmit={handleSubmit}>
          <label className="w-100 parent-label">
            Card details
            <CardElement
              options={options}
              onReady={() => {
                console.log("CardElement [ready]");
              }}
              onChange={(event) => {
                console.log("CardElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardElement [blur]");
              }}
              onFocus={() => {
                console.log("CardElement [focus]");
              }}
            />
          </label>
          <button type="submit" disabled={!stripe}>
            Pay
          </button>
        </form>
       )} 
    </>
  );
};

export default PaymentCard;
