import Close from "@mui/icons-material/Close";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "../../commonComponents/AppBar";

import InputComponent from "../../commonComponents/Input/InputComponent";
import ButtonComponent from "../../commonComponents/Button/ButtonComponent";
import jwt from "jwt-decode";
import _ from "lodash";
import axios from "../../axiosInstance";
import { cartArray } from "../../utils/Helpers";
// import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useEffect } from "react";
import SetAuthToken from "../../SetAuthToken";

const Login = () => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const _setState = (key, value) => {
    const obj = {};
    obj[key] = value;
    setFormData({ ...formData, ...obj });
  };

  useEffect(() => {
    document.title = "Paasa Login";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    try {
      response = await axios.post("/user/login", formData);
      // console.log(response, "respoooo");
      const token = response?.data?.data?.token;
      const user = jwt(token); // decode your token here
      // console.log(user, "user");
      cartArray([]);
      const userdata = {
        usertoken: response?.data?.data?.token,
      };
      localStorage.setItem("userData", JSON.stringify(userdata));
      SetAuthToken(userdata?.usertoken);
      const userMe = await axios.get("/user/me");
      if (user?.userRole === "FINANCIAL" && _.isEmpty(userMe?.data?.data?.userPlan)) {
        navigate("/MembershipPlan", {
          state: {
            signupToken: response?.data?.data?.token,
          },
        });
      } else {
        navigate("/AboutPaasa");
      }
      // navigate("/MembershipPlan", {
      //   state: {
      //     signupToken: response?.data?.data?.token,
      //   },
      // });
    } catch (e) {
      // if(e?.data)
      // toast.error(e?.response?.data?.message);
      Swal.fire("Error", e?.response?.data?.message, "error");
      // console.log(e.response);
    }
    return;
  };
  return (
    <>
      <AppBar />
      <div className="form-contan ">
        <div className="long-form  logIn-form " id="logIn">
          <div className="container-lg">
            {/* <div className="close-icon">
            <Close />
          </div> */}
            <div className="form-heading ">
              <h4>PAASA Member Log in </h4>
            </div>
            <form type="submit" onSubmit={handleSubmit}>
              <div class="row">
                <div className="col-12">
                  <InputComponent
                    required={true}
                    type="email"
                    className="form-control email-icon"
                    // id="inputEmail"
                    placeholder="Email"
                    onChange={(e) => _setState("email", e?.target?.value)}
                  />
                  {/* <input
                    required
                    type="email"
                    class="form-control email-icon"
                    id="inputEmail"
                    placeholder="Email"
                  /> */}
                  <InputComponent
                    required={true}
                    type="password"
                    className="form-control password-icon"
                    // id="inputpasswaord"
                    placeholder="password"
                    onChange={(e) => _setState("password", e?.target?.value)}
                  />
                  {/* <input
                    required
                    type="password"
                    class="form-control password-icon"
                    id="inputpasswaord"
                    placeholder="password"
                  /> */}
                  <div className="forget-password">
                    <Link to="/ForgetPassword"> Forgot password ? </Link>
                  </div>
                  {/* <button >Log in</button> */}
                  <ButtonComponent
                    className="btn btn-green "
                    text="Log in"
                    type="submit"
                  />
                  <div className="member">
                    New member? <Link to="/SignUp"> SIGN UP HERE </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
