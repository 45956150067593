import React, { useState } from "react";
import BookingDates from "./BookingDates";
import { BootstrapDialog } from "../../utils/Constants";
import DialogActions from "@mui/material/DialogActions";
import DialogBox from "../../commonComponents/DialogBox/DialogBox";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { BootstrapDialogTitle } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getToken } from "../../utils/Auth";
import { getCartArray } from "../../utils/Helpers";
const AssetsCards = ({
  AssetsData,
  Steps,
  setSteps,
  Open,
  setOpen,
  addToCart,
  formData,
  setFormData,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const token = getToken();
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  let localStorageCartArray = getCartArray();
  const handleCloseDialog = (props) => {
    console.log(props, "props");
    // if (props == 'agree') {
    //   setFormData({
    //     ...formData,
    //     isAcceptedTerms: true,
    //   });
    // }
    // if (props == 'disagree') {
    //   setFormData({
    //     ...formData,
    //     isAcceptedTerms: false,
    //   });
    // }
    // if (props == 'cancel_booking') {
    //   setFormData({});
    // }
    setOpenDialog(false);
  };
  const handleClickOpen = (key, id, values) => {
    console.log(values, "values");
    if (token) {
      const obj = {};
      obj[key] = id;
      let objtwo = {};
      objtwo["name"] = values?.name;
      if (localStorageCartArray.length == 0) {
        setFormData({ ...formData, ...obj, ...objtwo });
        setOpen(true);
        setSteps("1");
      }
      if (localStorageCartArray.length > 0) {
        setFormData({ ...formData, ...obj, ...objtwo });
        addToCart(id, values?.name);
      }
    } else {
      navigate("/LogIn");
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSteps("1");
  };
  const navToViewCart = () => {
    navigate("/ViewCart");
  };
  return (
    <>
      <div className="row">
        <div className="d-flex w-100 space-between">
          <h1 className="pg-title">Available Assets</h1>
          <div class="floating-button">
            <AddShoppingCartIcon
              sx={{ width: "40px", height: "40px" }}
              onClick={navToViewCart}
            />
          </div>
        </div>

        {AssetsData?.map((value, index) => {
          return (
            <div class=" col-xl-3 col-sm-6 col-12">
              <div class="card text-center">
                <img class="card-img-top " src={value?.image} alt="Card cap" />
                <div class="card-body">
                  <p class="card-title">{value?.name}</p>
                  {/* <p>
                    Model: {value?.model} Seats:{value?.seatingCapacity}{' '}
                  </p> */}
                  <p class="card-title">${value?.dailyRent}/day </p>

                  <button
                    id={value?.id}
                    onClick={() => {
                      handleClickOpen("assetId", value?.id, value);
                      // setVehicleFormData(value);
                      // getUserInfo();
                    }}
                    className="btn btn-green "
                  >
                    Add to cart
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <BootstrapDialog
          //onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={Open}
        >
          <DialogBox
            handleClickOpenDialog={handleClickOpenDialog}
            handleCloseDialog={handleCloseDialog}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
          {Steps === "1" ? (
            <>
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              ></BootstrapDialogTitle>
              <div className="long-form ">
                <div className="container-lg">
                  <div className="form-heading">
                    <h4>Select Booking Date</h4>
                  </div>
                  <div class="row justify-content">
                    <BookingDates
                      addToCart={addToCart}
                      formData={formData}
                      setFormData={setFormData}
                      VehicleData={AssetsData?.filter(
                        (val) => val?.id === formData?.assetId
                      )}
                    />
                    <DialogActions></DialogActions>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </BootstrapDialog>
      </div>
    </>
  );
};

export default AssetsCards;
